import _ax from './_ax';

export function postWithdrawal(params){
	return _ax.post('withdrawal', params);
}

export function postRecharge(params){
	return _ax.post('recharge', params);
}

export function getProfile(){
	return _ax.get('profile');
}

export function putChangeProfile(data){
	return _ax.put('profile', data);
}

export function putChangePassword(old_password, new_password){
	return _ax.put('profile/password', { old_password, new_password });
}

export function resetDemoBalance(){
	return _ax.put('profile/reset-demo-balance');
}

export function postChangeAvatar(avatar){
	let formData = new FormData();
	formData.append("avatar", avatar);
	return _ax.post('profile/avatar', formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
}

export function getTransaction(params){
	return _ax.get('transaction', { params });
}

export function postBuyVip(){
	return _ax.post('buy-vip');
}

export function postOTPEmail(){
	return _ax.post('otp-email');
}

export function getTwoFaKey(){
	return _ax.get('two-fa-key');
}

export function postEnableTwoFa(data){
	return _ax.post('enable-two-fa', data);
}

export function postDisableTwoFa(data){
	return _ax.post('disable-two-fa', data);
}

export function getCommission(){
	return _ax.get('commission');
}

export function getNetwork(){
	return _ax.get('network');
}

export function postTransfer(data){
	return _ax.post('transfer', data);
}

export function postSendOTP(){
	return _ax.post('send-otp');
}

export function getOtherMember(params){
	return _ax.get('other-member', { params });
}

export function getAgency(params){
	return _ax.get('agency', { params });
}

export function getNation(){
	return _ax.get('/location/nation', {  });
}

export function putVerifyInfoUser(data){
	return _ax.put('profile/verify-account', data);
}

export function putAddBankInfo(data){
	return _ax.put('profile/add-bank-info', data);
}
