import '../../assets/css/login.css';

import { Component } from 'react';
import api from "../../lib/api";
import {connect} from "react-redux";
import { fetchingUserSuccess } from '../../redux/actions';
import {withRouter} from 'react-router-dom';

import {getJSONFromUrl, navigateTo, showNotification} from "../../lib/helper";

class ForgotPassword extends Component{
	constructor(props) {
		super(props);
		this.state = {
			email: this.props.location.state?.email || '',
			password: '',
			is_show_password: false,
			token: ''
		};
	}

	componentDidMount() {
		let {e, token} = getJSONFromUrl();
		this.setState({ email: e, token: token })
	}

	onChangeInput = (type) => (e) => {
		this.setState({ [type]: e.target.value });
	}

	onKeyPress = (e) => {
		if(e.code === 'Enter'){
			this.onSignIn(e);
		}
	}

	onToggleShowPassword = () => {
		this.setState({ is_show_password: !this.state.is_show_password })
	}

	onResetPassword = (e) => {
		let {email,token,password} = this.state;
		let isDisableSignButton = !email || email.length === 0;
		if(isDisableSignButton){
			return false;
		}
		api.resetPassword({ email, token, password })
			.then(({ message, success}) => {
				showNotification({message, type: success ? 'success' : 'danger'});
				if(!success){
					return null;
				}
				navigateTo('login', { email: email }).bind(this)(e);
			});
	}

	render() {
		let {email, password, is_show_password } = this.state;
		let isDisableSignButton = !email || email.length === 0 || !password || password.length === 0;
		let { asset_logo, seo_title } = this.props.appReducer;
		return (
			<div data-v-b0e45e8c="" id="mainContent">
				<div data-v-b0e45e8c="" className="boxContent">
					<div data-v-f99ecb42="" data-v-b0e45e8c="" className="boxFix">
						<div data-v-f99ecb42="" className="boxAuthentication show">
							<div data-v-f99ecb42=""
								 className="contentAuthentication d-flex flex-column align-items-center justify-content-between">
								<div data-v-f99ecb42=""
									 className="header d-flex align-items-center justify-content-between w-100">
									<a data-v-f99ecb42="" href="/"
									   style={{ backgroundImage: `url(${asset_logo})` }}
									   className="logoLogin mb-0 router-link-active">BoTrade</a>
									<div data-v-f99ecb42="" onClick={navigateTo('/').bind(this)} className="close-page">
										<span data-v-f99ecb42="" className="pe pe-7s-close" />
									</div>
								</div>
								<div data-v-f99ecb42="" className="formWapper w-100">
									<form data-v-f99ecb42="" className="loginForm colorWhite mt-0"><h2
										data-v-f99ecb42=""
										className="mt5m font-24 color-white font-weight-bold mb-lg-5 mb-3">Đặt lại mật khẩu</h2>
										<div data-v-f99ecb42=""
											 className="md-field md-theme-default md-has-placeholder"><label
											data-v-f99ecb42="" htmlFor="md-input-3037ci4xp">Địa chỉ email *</label>
											<input data-v-f99ecb42="" value={email} disabled={true} onKeyPress={this.onKeyPress} onChange={this.onChangeInput('email')} type="text" aria-autocomplete="false"
												   autoComplete="off" id="md-input-3037ci4xp" placeholder="Điền email" className="md-input" />
												   <small className="md-error" />
										</div>
										<div data-v-f99ecb42=""
											 className="md-field mb-2 md-theme-default md-has-placeholder md-has-password">
											<label data-v-f99ecb42="" htmlFor="md-input-l9w3oo4ed">Mật khẩu mới *</label>
											<input data-v-f99ecb42="" aria-autocomplete="false"
												   type={is_show_password ? 'text' : 'password'}
												   autoComplete="off" id="md-input-l9w3oo4ed" value={password} onKeyPress={this.onKeyPress} onChange={this.onChangeInput('password')}
												   placeholder="Điền mật khẩu" className="md-input password" /> <small data-v-f99ecb42="" className="md-error" />
											<button type="button"
													onClick={this.onToggleShowPassword}
													className="md-button md-icon-button md-dense md-input-action md-toggle-password md-theme-default"
													tabIndex="-1">
												<div className="md-ripple">
													<div className="md-button-content">
														<i className="md-icon md-icon-font md-icon-image md-theme-default">
															{is_show_password ?
																<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
																	<path d="M0 0h24v24H0z" fill="none"/> <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>
																</svg>
																:<svg height="24" viewBox="0 0 24 24" width="24"
																	  xmlns="http://www.w3.org/2000/svg">
																	<path
																		d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z"
																		fill="none"/>
																	<path
																		d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z" />
																</svg>}
														</i>
													</div>
												</div>
											</button>
										</div>
										<div data-v-f99ecb42="" className="form-group text-center">
											<button data-v-f99ecb42="" type='button' onClick={this.onResetPassword} disabled={isDisableSignButton}
													className={'btn-large btn-radius w-100 ' + (isDisableSignButton ? 'btn-secondary btn' : 'siginButton button wbtn')}> Đặt lại mật khẩu</button>
										</div>
									</form>
								</div>
								<div data-v-f99ecb42="" className="">
									<p data-v-f99ecb42="" className="colorGray bottomText"> Cần có tài khoản {seo_title}?
										<a data-v-f99ecb42="" href="#" onClick={navigateTo('login').bind(this)} className="colorBlue4"> Đăng Nhập</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default connect(mapStateToProps, {
	fetchingUserSuccess
})(withRouter(ForgotPassword));

