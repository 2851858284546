import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import '../../src/assets/css/language-selector.css'

function LanguageSelector() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="language-selector">
      <select
        onChange={(e) => changeLanguage(e.target.value)}
        defaultValue="vi"
        className='pointer round-btn'
      >
        <option value="vi">
          🇻🇳 VN
        </option>
        <option value="en">
          🇬🇧 EN
        </option>
        <option value="zh">
          🇨🇳 中文
        </option>
      </select>
    </div>
  );
}

export default LanguageSelector;
