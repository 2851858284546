import { Component } from 'react';
import { connect } from "react-redux";
import api from "../../lib/api";
import {
	formatNumber,
	showNotification,
	setClassNameCondition,
	isMobile,
	isDesktop, getSocket
} from "../../lib/helper";
import { fetchingUser, fetchingTransactionAction } from "../../redux/actions";
import AnalysisWrapper from "./partial/AnalysisWrapper";
import TradeButtons from "./partial/TradeButtons";
import TransactionBox from "../../lib/custom/TransactionBox";
import { withTranslation } from 'react-i18next';

class Analysis extends Component {
	constructor(props) {
		super(props);
		this.state = {
			money_value: 50,
			psychological_indicators: 0,
			is_show_keyboard: false,
			is_bet: false,
			second: 0,
			bet_profit: 0,
			minPrice: {
				10: 100,
				20: 1000,
				30: 5000,
				40: 10000,
				50: 50000
			}
		};
	}

	onClickMoney = (money_value) => () => {
		let { current_point_type } = this.props.memberReducer;
		let balance = this.props.memberReducer[`${current_point_type}_balance`];
		if (money_value === 'ALL') {
			return this.setState({ money_value: balance });
		}
		if (money_value === 'ADD') {
			return this.setState({ money_value: this.state.money_value + 5 });
		}
		if (money_value === 'SUB') {
			let value = this.state.money_value - 5;
			value = value < 0 ? 0 : value;
			return this.setState({ money_value: value });
		}
		money_value = this.state.money_value + money_value;
		if (money_value > balance) {
			money_value = balance;
		}
		this.setState({ money_value: money_value });
	}

	onClickProfit = (profit) => () => {
		this.setState({ bet_profit: profit, money_value: this.state.minPrice[profit] })
	}

	onBet = (bet_condition) => () => {
		let min_price = this.state.minPrice[this.state.bet_profit];
		if (this.state.money_value < min_price) {
			return showNotification({ message: `Giá trị đặt cược không được nhỏ hơn ${formatNumber(min_price)} USD!`, type: 'danger' });
		}
		api.postBet({ bet_condition, bet_value: this.state.money_value, profit_percent: this.state.bet_profit })
			.then(({ data, success, message }) => {
				showNotification({ message, type: success ? 'success' : 'danger' });
				if (success) {
					this.props.memberReducer?.enable_sound && window.document.getElementById('bet')?.play();
					this.props.fetchingUser();
					this.props.fetchingTransactionAction();
				}
			});
	}

	onClear = () => {
		this.setState({ money_value: 0 });
	}

	onChangeMoneyValue = (e) => {
		let money_value = parseFloat(e.target.value.toString().replace(/,/g, '')) || 0;
		this.setState({ money_value });
	}

	onShowKeyboard = () => {
		this.setState({ is_show_keyboard: !this.state.is_show_keyboard });
	}

	componentDidMount() {
		this.props.fetchingTransactionAction();
		getSocket().then(socket => {
			socket.on('WE_PRICE', ({ is_bet, second, psychological_indicators }) => {
				this.setState({ is_bet, second, psychological_indicators });
			});
		});
	}

	componentWillUnmount() {
		window.socket && window.socket.removeAllListeners("WE_PRICE");
	}

	setMoney = (value) => () => {
		let { current_point_type } = this.props.memberReducer;
		let balance = this.props.memberReducer[`${current_point_type}_balance`];
		let money_value = parseFloat(this.state.money_value === 0 ? value : this.state.money_value + '' + value);
		if (money_value > balance) {
			money_value = balance;
		}
		this.setState({ money_value: money_value });
	}

	removeMoney = () => {
		let money_value = this.state.money_value.toString();
		money_value = money_value.length > 1 ? money_value.substr(0, money_value.length - 1) : 0;
		this.setState({ money_value: parseFloat(money_value) });
	}

	render() {
		let profit_percent = this.props.appReducer.profit_percent || 0;
		let { money_value, is_show_keyboard, psychological_indicators, is_bet, second, bet_profit } = this.state;
		let reduce_value = money_value;
		let profit_value = Math.floor(reduce_value + (reduce_value * profit_percent / 100));
		let { is_show_transaction } = this.props.appReducer;
		const { t } = this.props;
		return (
			<div data-v-5b2de64c="" id="analysis-bet-wrapper" className="wrap">
				{/* {isMobile() && <AnalysisWrapper trading_data={this.props.trading_data} />} */}
				<div data-v-5b2de64c="" id="rightContent"
					className={setClassNameCondition(is_show_transaction, '', 'hideTransaction', 'rightContent')}>
					<div data-v-5b2de64c="" className="d-flex h-100">
						<div data-v-5b2de64c="" className={setClassNameCondition(is_show_transaction, '', 'flex-100', 'flex-50')}>
							<div data-v-6ef41282="" data-v-5b2de64c="" id="betAmount">
								{isDesktop() ?
									<div data-v-5a313b7e="" data-v-6ef41282="" className="amount-wrapper">
										<div data-v-5a313b7e="" className="profit">
											<p data-v-5a313b7e=""
												className="my-lg-2 pt-lg-1 pb-lg-1">% {t('trading.profit')}</p>
											<div data-v-5a313b7e="" className="keyboardAmount mt-2">
												<div data-v-5a313b7e="" className="row">
													{[10, 20, 30, 40, 50].map((num, index) => {
														return <div data-v-5a313b7e="" key={index}
															onClick={this.onClickProfit(num)}
															className="col-4 mb-2">
															<a data-v-5a313b7e="" href="#"
																onClick={e => e.preventDefault()}>
																<span data-v-5a313b7e="" className={bet_profit == num ? 'bagItem profit_active' : 'bagItem'}>
																	<span data-v-5a313b7e="">+</span>{num}%
																</span>
															</a>
														</div>
													})}
												</div>
											</div>
											{/* <div data-v-5a313b7e=""><span
												data-v-5a313b7e=""
												className="profitPercent color-light-blue ml-2 mb-2 d-inline-block">{profit_percent}%&#8287;</span>
												<span data-v-5a313b7e=""
													  className="profitValue color-green font-30 font-weight-700 d-inline-block">+{profit_value}</span>
											</div> */}
										</div>
										<p data-v-5a313b7e="" className="font-14 mt-2 mb-2 mb1Landscape">{t('trading.value')}</p>
										<div data-v-5a313b7e="">
											<div data-v-5a313b7e=""
												className="inputGroup">
												<input
													data-v-3eb1cc46=""
													onChange={this.onChangeMoneyValue}
													autoComplete='off'
													data-v-5a313b7e="" type="text"
													id="InputNumber" value={formatNumber(money_value)} /> <span
													data-v-5a313b7e=""
													className="colorWhite icon pr-2 currency" />
												<span data-v-5a313b7e="" onClick={this.onClear} className="btnClear">
													<img alt='' data-v-5a313b7e=""
														src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMSIgdmlld0JveD0iMCAwIDIxIDIxIj4KICA8ZyBpZD0iR3JvdXBfMTExMzQiIGRhdGEtbmFtZT0iR3JvdXAgMTExMzQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMjY4LjM0NSAtMjguMzQ1KSI+CiAgICA8Y2lyY2xlIGlkPSJFbGxpcHNlXzIwMSIgZGF0YS1uYW1lPSJFbGxpcHNlIDIwMSIgY3g9IjEwLjUiIGN5PSIxMC41IiByPSIxMC41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMjY4LjM0NSAyOC4zNDUpIiBmaWxsPSIjZmZmIiBvcGFjaXR5PSIwLjE5Ii8+CiAgICA8ZyBpZD0iZS1yZW1vdmUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyNzQuNjI0IDM0LjYyNCkiPgogICAgICA8cGF0aCBpZD0iUGF0aF8xMzc4NCIgZGF0YS1uYW1lPSJQYXRoIDEzNzg0IiBkPSJNOS4wNDcsMS4xNzZhLjU2OC41NjgsMCwwLDAtLjgyMiwwTDUuMTExLDQuMjg5LDIsMS4xNzZhLjU2OC41NjgsMCwwLDAtLjgyMiwwLC41NjguNTY4LDAsMCwwLDAsLjgyMkw0LjI4OSw1LjExMSwxLjE3Niw4LjIyNGEuNTY4LjU2OCwwLDAsMCwwLC44MjIuNTMzLjUzMywwLDAsMCwuNDExLjE3NkEuNTMzLjUzMywwLDAsMCwyLDkuMDQ3TDUuMTExLDUuOTM0LDguMjI0LDkuMDQ3YS41NjguNTY4LDAsMCwwLC44MjIsMCwuNTY4LjU2OCwwLDAsMCwwLS44MjJMNS45MzQsNS4xMTEsOS4wNDcsMkEuNTY4LjU2OCwwLDAsMCw5LjA0NywxLjE3NloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xIC0xKSIgZmlsbD0iI2ZmZiIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg==" />
												</span>
											</div>
											<div data-v-5a313b7e="" className="keyboardAmount mt-2">
												<div data-v-5a313b7e="" className="row">
													{[5, 10, 20, 50, 100].map((num, index) => {
														return <div data-v-5a313b7e="" key={index}
															onClick={this.onClickMoney(num)}
															className="col-4 mb-2">
															<a data-v-5a313b7e="" href="#"
																onClick={e => e.preventDefault()}>
																<span data-v-5a313b7e="" className="bagItem">
																	<span data-v-5a313b7e="">+</span>{num}
																</span>
															</a>
														</div>
													})}
													<div data-v-5a313b7e=""
														onClick={this.onClickMoney('ALL')}
														className="col-4 mb-2"><a
															data-v-5a313b7e=""
															href="#" onClick={e => e.preventDefault()}><span
																data-v-5a313b7e=""
																className="bagItem">{t('trading.all')}</span></a>
													</div>
												</div>
											</div>
										</div>
										<div data-v-5a313b7e="" className="slider mb-4"><p
											data-v-5a313b7e=""
											className="mt-2 pt-1 pb-1">{t('trading.sentiment_indicator')}</p>
											<div data-v-5a313b7e="">
												<div data-v-5a313b7e=""
													className="progress active">
													<div data-v-5a313b7e=""
														role="progressbar"
														aria-valuenow="0"
														aria-valuemin="0"
														aria-valuemax="100"
														className="progress-bar"
														style={{ width: psychological_indicators + '%' }} />
												</div>
												<div data-v-5a313b7e="" className="d-flex justify-content-between">
													<span data-v-5a313b7e=""
														className="colorDown">{100 - psychological_indicators}%</span>
													<span data-v-5a313b7e=""
														className="colorUp">{psychological_indicators}%</span>
												</div>
											</div>
										</div>
									</div>
									:
									<div data-v-5a313b7e="" data-v-6ef41282="" className="amount-wrapper">
										<div data-v-5a313b7e="" className="slider sidebarMobile"
											style={{ height: '401px' }}>
											{/* <div data-v-5a313b7e="" className="slidercontent">
												<div data-v-5a313b7e="" className="backgroundOverlay" />
												<div data-v-5a313b7e=""
													className="d-flex flex-column justify-content-between"
													style={{
														height: '351px',
														paddingTop: '5px',
														marginLeft: '10px'
													}}><span
														data-v-5a313b7e=""
														className="colorUp">{psychological_indicators}%</span>
													<div data-v-5a313b7e="" className="progress active"
														style={{ width: '301px' }}>
														<div data-v-5a313b7e="" role="progressbar" aria-valuenow="0"
															aria-valuemin="0" aria-valuemax="100"
															className="progress-bar"
															style={{ width: psychological_indicators + '%' }} />
													</div>
													<span data-v-5a313b7e=""
														className="colorDown">{100 - psychological_indicators}%</span>
												</div>
											</div> */}
										</div>
										<div data-v-5a313b7e=""
											className="profit pt-0">
											<p data-v-5a313b7e="" className="mt-lg-2 pt-lg-1 pb-lg-1">% {t('trading.profit')}</p>
											<div data-v-5a313b7e="" className="keyboardAmount mt-2">
												<div data-v-5a313b7e="" className="row">
													{[10, 20, 30, 40, 50].map((num, index) => {
														return <div data-v-5a313b7e="" key={index}
															onClick={this.onClickProfit(num)}
															className="col-4 mb-2">
															<a data-v-5a313b7e="" href="#"
																onClick={e => e.preventDefault()}>
																<span data-v-5a313b7e="" className={bet_profit == num ? 'bagItem profit_active' : 'bagItem'}>
																	<span data-v-5a313b7e="">+</span>{num}%
																</span>
															</a>
														</div>
													})}
												</div>
											</div>
										</div>
										<div data-v-5a313b7e="" className="groupButtonMobile d-flex mb-2">
											<button data-v-5a313b7e="" type="button"
												onClick={this.onClickMoney('SUB')}
												className="btn btnTransparent w-100 mr-1">
												<svg data-v-5a313b7e="" xmlns="http://www.w3.org/2000/svg" width="14"
													height="2" viewBox="0 0 14 2" className="iconAction">
													<g data-v-5a313b7e="" id="search-zoom-in"
														transform="translate(-7.991 -13.991)">
														<line data-v-5a313b7e="" id="Line_1142" data-name="Line 1142"
															x1="12" transform="translate(8.991 14.991)" fill="none"
															stroke="#fff" strokeLinecap="round"
															strokeLinejoin="round" strokeMiterlimit="10"
															strokeWidth="2" />
													</g>
												</svg>
											</button>
											<div data-v-5a313b7e="" className="inputGroup d-flex align-items-center">
												<input
													data-v-3eb1cc46="" data-v-5a313b7e="" type="text"
													onChange={this.onChangeMoneyValue}
													value={formatNumber(money_value)}
													onFocus={this.onShowKeyboard}
													autoComplete='off'
													readOnly={true}
													className="font-12m" />
												<span data-v-5a313b7e="" onClick={this.onClear} className="btnClear">
													<img data-v-5a313b7e=""
														src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMSIgdmlld0JveD0iMCAwIDIxIDIxIj4KICA8ZyBpZD0iR3JvdXBfMTExMzQiIGRhdGEtbmFtZT0iR3JvdXAgMTExMzQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMjY4LjM0NSAtMjguMzQ1KSI+CiAgICA8Y2lyY2xlIGlkPSJFbGxpcHNlXzIwMSIgZGF0YS1uYW1lPSJFbGxpcHNlIDIwMSIgY3g9IjEwLjUiIGN5PSIxMC41IiByPSIxMC41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMjY4LjM0NSAyOC4zNDUpIiBmaWxsPSIjZmZmIiBvcGFjaXR5PSIwLjE5Ii8+CiAgICA8ZyBpZD0iZS1yZW1vdmUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyNzQuNjI0IDM0LjYyNCkiPgogICAgICA8cGF0aCBpZD0iUGF0aF8xMzc4NCIgZGF0YS1uYW1lPSJQYXRoIDEzNzg0IiBkPSJNOS4wNDcsMS4xNzZhLjU2OC41NjgsMCwwLDAtLjgyMiwwTDUuMTExLDQuMjg5LDIsMS4xNzZhLjU2OC41NjgsMCwwLDAtLjgyMiwwLC41NjguNTY4LDAsMCwwLDAsLjgyMkw0LjI4OSw1LjExMSwxLjE3Niw4LjIyNGEuNTY4LjU2OCwwLDAsMCwwLC44MjIuNTMzLjUzMywwLDAsMCwuNDExLjE3NkEuNTMzLjUzMywwLDAsMCwyLDkuMDQ3TDUuMTExLDUuOTM0LDguMjI0LDkuMDQ3YS41NjguNTY4LDAsMCwwLC44MjIsMCwuNTY4LjU2OCwwLDAsMCwwLS44MjJMNS45MzQsNS4xMTEsOS4wNDcsMkEuNTY4LjU2OCwwLDAsMCw5LjA0NywxLjE3NloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xIC0xKSIgZmlsbD0iI2ZmZiIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg==" />
												</span>
											</div>
											<button data-v-5a313b7e="" type="button"
												onClick={this.onClickMoney('ADD')}
												className="btn btnTransparent w-100 ml-1">
												<svg data-v-5a313b7e="" xmlns="http://www.w3.org/2000/svg" width="14"
													height="14" viewBox="0 0 14 14" className="iconAction">
													<g data-v-5a313b7e="" id="search-zoom-in"
														transform="translate(-7.991 -7.991)">
														<line data-v-5a313b7e="" id="Line_1141" data-name="Line 1141"
															y2="12" transform="translate(14.991 8.991)" fill="none"
															stroke="#fff" strokeLinecap="round"
															strokeLinejoin="round" strokeMiterlimit="10"
															strokeWidth="2" />
														<line data-v-5a313b7e="" id="Line_1142" data-name="Line 1142"
															x1="12" transform="translate(8.991 14.991)" fill="none"
															stroke="#fff" strokeLinecap="round"
															strokeLinejoin="round" strokeMiterlimit="10"
															strokeWidth="2" />
													</g>
												</svg>
											</button>
										</div>
									</div>
								}

								<div data-v-05054441="" data-v-6ef41282="" className="bet-wrapper">
									<TradeButtons is_bet={is_bet} second={second} onBet={this.onBet} />
								</div>

								{isMobile() && <div data-v-f72aa7e6='' data-v-5a313b7e='' className={setClassNameCondition(is_show_keyboard, 'active', '', 'keyboard-input-wrap')} style={{ pointerEvents: 'auto' }}>
									<div data-v-f72aa7e6='' className="numKeyboardInput">
										<div style={{
											position: 'absolute',
											height: '100%',
											width: '100%',
											zIndex: '9998'
										}} onClick={this.onShowKeyboard} />
										<div data-v-53be5341='' data-v-f72aa7e6='' className={setClassNameCondition(is_show_keyboard, '', 'hidden', 'keyboard')}>
											<div data-v-53be5341="" id="amountWrapper" className="amountWrapper">
												<div data-v-53be5341="" className="headerAmount"><span
													data-v-53be5341="">{t('trading.set_value')}</span></div>
												<div data-v-53be5341="" className="groupButtonMobile d-flex">
													<button data-v-53be5341="" type="button"
														onClick={this.onClickMoney('SUB')}
														className="btn btnTransparent w-100 mr-1">
														<svg data-v-53be5341="" xmlns="http://www.w3.org/2000/svg"
															width="14" height="2" viewBox="0 0 14 2"
															className="iconAction">
															<g data-v-53be5341="" id="search-zoom-in"
																transform="translate(-7.991 -13.991)">
																<line data-v-53be5341="" id="Line_1142"
																	data-name="Line 1142" x1="12"
																	transform="translate(8.991 14.991)" fill="none"
																	stroke="#fff" strokeLinecap="round"
																	strokeLinejoin="round" strokeMiterlimit="10"
																	strokeWidth="2" />
															</g>
														</svg>
													</button>
													<div data-v-53be5341=""
														className="inputGroup d-flex align-items-center"><input
															onChange={this.onChangeMoneyValue}
															value={formatNumber(money_value)}
															readOnly={true}
															data-v-3eb1cc46="" data-v-53be5341="" type="text" className="font-12m inputAmount"
															id="inputKeyboard" /> <span data-v-53be5341=""
																onClick={this.onClear}
																className="btnClear"><img
																data-v-53be5341=""
																src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMSIgdmlld0JveD0iMCAwIDIxIDIxIj4KICA8ZyBpZD0iR3JvdXBfMTExMzQiIGRhdGEtbmFtZT0iR3JvdXAgMTExMzQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMjY4LjM0NSAtMjguMzQ1KSI+CiAgICA8Y2lyY2xlIGlkPSJFbGxpcHNlXzIwMSIgZGF0YS1uYW1lPSJFbGxpcHNlIDIwMSIgY3g9IjEwLjUiIGN5PSIxMC41IiByPSIxMC41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMjY4LjM0NSAyOC4zNDUpIiBmaWxsPSIjZmZmIiBvcGFjaXR5PSIwLjE5Ii8+CiAgICA8ZyBpZD0iZS1yZW1vdmUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyNzQuNjI0IDM0LjYyNCkiPgogICAgICA8cGF0aCBpZD0iUGF0aF8xMzc4NCIgZGF0YS1uYW1lPSJQYXRoIDEzNzg0IiBkPSJNOS4wNDcsMS4xNzZhLjU2OC41NjgsMCwwLDAtLjgyMiwwTDUuMTExLDQuMjg5LDIsMS4xNzZhLjU2OC41NjgsMCwwLDAtLjgyMiwwLC41NjguNTY4LDAsMCwwLDAsLjgyMkw0LjI4OSw1LjExMSwxLjE3Niw4LjIyNGEuNTY4LjU2OCwwLDAsMCwwLC44MjIuNTMzLjUzMywwLDAsMCwuNDExLjE3NkEuNTMzLjUzMywwLDAsMCwyLDkuMDQ3TDUuMTExLDUuOTM0LDguMjI0LDkuMDQ3YS41NjguNTY4LDAsMCwwLC44MjIsMCwuNTY4LjU2OCwwLDAsMCwwLS44MjJMNS45MzQsNS4xMTEsOS4wNDcsMkEuNTY4LjU2OCwwLDAsMCw5LjA0NywxLjE3NloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xIC0xKSIgZmlsbD0iI2ZmZiIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg==" />
														</span>
													</div>
													<button data-v-53be5341="" type="button"
														onClick={this.onClickMoney('ADD')}
														className="btn btnTransparent w-100 ml-1">
														<svg data-v-53be5341="" xmlns="http://www.w3.org/2000/svg"
															width="14" height="14" viewBox="0 0 14 14"
															className="iconAction">
															<g data-v-53be5341="" id="search-zoom-in"
																transform="translate(-7.991 -7.991)">
																<line data-v-53be5341="" id="Line_1141"
																	data-name="Line 1141" y2="12"
																	transform="translate(14.991 8.991)" fill="none"
																	stroke="#fff" strokeLinecap="round"
																	strokeLinejoin="round" strokeMiterlimit="10"
																	strokeWidth="2" />
																<line data-v-53be5341="" id="Line_1142"
																	data-name="Line 1142" x1="12"
																	transform="translate(8.991 14.991)" fill="none"
																	stroke="#fff" strokeLinecap="round"
																	strokeLinejoin="round" strokeMiterlimit="10"
																	strokeWidth="2" />
															</g>
														</svg>
													</button>
												</div>
												<div data-v-53be5341=""
													className="d-flex align-items-center justify-content-center"><span
														data-v-53be5341="" className="color-white-50">{t('trading.profit')}</span>
													<div data-v-5a313b7e="" className="keyboardAmount mt-2">
														<div data-v-5a313b7e="" className="row">
															{[10, 20, 30, 40, 50].map((num, index) => {
																return <div data-v-5a313b7e="" key={index}
																	onClick={this.onClickProfit(num)}
																	className="col-4 mb-2">
																	<a data-v-5a313b7e="" href="#"
																		onClick={e => e.preventDefault()}>
																		<span data-v-5a313b7e="" className={bet_profit == num ? 'bagItem profit_active' : 'bagItem'}>
																			<span data-v-5a313b7e="">+</span>{num}%
																		</span>
																	</a>
																</div>
															})}
														</div>
													</div>
												</div>
											</div>
											<table data-v-53be5341="" id="keyboardLandscape">
												<tbody data-v-53be5341="">
													<tr data-v-53be5341="" className="suggestion">
														<td data-v-53be5341="" data-code="H" onClick={this.onClickMoney(5)}>+5</td>
														<td data-v-53be5341="" data-code="H" onClick={this.onClickMoney(10)}>+10</td>
														<td data-v-53be5341="" data-code="H" onClick={this.onClickMoney(50)}>+50</td>
														<td data-v-53be5341="" data-code="H" onClick={this.onClickMoney(100)}>+100</td>
													</tr>
													<tr data-v-53be5341="">
														<td data-v-53be5341="" onClick={this.setMoney(1)}>1</td>
														<td data-v-53be5341="" onClick={this.setMoney(2)}>2</td>
														<td data-v-53be5341="" onClick={this.setMoney(3)}>3</td>
														<td data-v-53be5341="" data-code="ALL"
															onClick={this.onClickMoney('ALL')} rowSpan="2"><span
																data-v-53be5341="">{t('trading.all')}</span></td>
													</tr>
													<tr data-v-53be5341="">
														<td data-v-53be5341="" onClick={this.setMoney(4)}>4</td>
														<td data-v-53be5341="" onClick={this.setMoney(5)}>5</td>
														<td data-v-53be5341="" onClick={this.setMoney(6)}>6</td>
													</tr>
													<tr data-v-53be5341="">
														<td data-v-53be5341="" onClick={this.setMoney(7)}>7</td>
														<td data-v-53be5341="" onClick={this.setMoney(8)}>8</td>
														<td data-v-53be5341="" onClick={this.setMoney(9)}>9</td>
														<td data-v-53be5341="" data-code="K" rowSpan="2" onClick={this.onShowKeyboard} className="">Xong</td>
													</tr>
													<tr data-v-53be5341="">
														<td data-v-53be5341="">.</td>
														<td data-v-53be5341="" onClick={this.setMoney(0)}>0</td>
														<td data-v-53be5341="" onClick={this.removeMoney} data-code="D"><span data-v-53be5341=""
															className="icon del" /></td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>}

							</div>
						</div>
						{is_show_transaction && <div data-v-5b2de64c="" className="flex-50 px-1">
							<TransactionBox />
						</div>}
					</div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		chartReducer: state.chartReducer,
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default connect(mapStateToProps, {
	fetchingUser,
	fetchingTransactionAction
})(withTranslation()(Analysis));
