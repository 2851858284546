import '../../assets/css/home.css';

import { Component } from 'react';
import { navigateTo, isDesktop, formatNumber } from "../../lib/helper";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import io from 'socket.io-client';
import formatNum from 'format-num';
import { withTranslation } from 'react-i18next';

class Home extends Component {

	constructor(props) {
		super(props);
		this.state = {
			coinData: [],
		};
	}

	componentDidMount() {
		// if (!this.props.memberReducer?.login_token) {
		// 	return this.props.history.push('/');
		// }

		io(this.props.appReducer.socket_link).on('coinData', (data) => {
			this.setState({ coinData: data });
		});
	}

	componentWillUnmount() {
		io(this.props.appReducer.socket_link).disconnect();
	}

	render() {
		let { window_width, asset_logo, asset_home_app, message_notify_home, cskh_link, seo_title } = this.props.appReducer;
		const { coinData } = this.state;
		const { t } = this.props;
		if (!this.props.memberReducer?.login_token) {
			const chat_widget = document.getElementById('chat-widget-container');
			if(chat_widget) {
				chat_widget.style.display = 'none';
			}
		}
		return (
			<div data-v-b0e45e8c="" id="mainContent">
				{(isDesktop && message_notify_home != '') && <marquee className='bg-success colorWhite p-2 mt-2'>{message_notify_home}</marquee>}
				<div data-v-b0e45e8c="" className="boxContent">
					<div data-v-5954443c="" data-v-b0e45e8c="" className="homepage">
						<section data-v-5954443c="" className="backgroundImg profitabilityon pt-5">
							<div data-v-5954443c="" className="boxContent">
								<div data-v-5954443c="" className="container h-100">
									<div data-v-5954443c="" className="row h-100">
										<div data-v-5954443c="" className="col-lg-5 col6Landscape">
											<div data-v-5954443c="" className=" d-flex flex-column justify-content-center h-100 pr-5 pr-0Landscape">
												<p data-v-5954443c="" className="wbo-title titleLarge font-30m typewriter mb-5">{t('home.title')}</p>
												<p data-v-5954443c="" className="font-18 color-white font-16m mb-5">{t('home.subtitle', { seo_title: seo_title })}</p>
												<a data-v-5954443c="" href="#" onClick={navigateTo('trading').bind(this)} className="button wbtn height-60 btn-large btn-radius font-20 w-75 w-100l font-16m bgSecondaryBtn3">{t('home.btn_start')}</a>
											</div>
										</div>
										<div data-v-5954443c="" className="col-lg-5  col6Landscape offset-lg-2">
											<div data-v-5954443c="" className="d-flex align-items-center h-100">
												<div data-v-5954443c="" style={{ backgroundImage: `url(${asset_home_app})` }} className="backgroundImg contain backgroundChart" />
											</div>
										</div>

									</div>
								</div>
							</div>
							{/* <div data-v-5954443c="" className="backgroundB">
									<div data-v-5954443c="" className="backgroundImg backgroundBridge" />
								</div> */}

						</section>
						{isDesktop() && <div class="marquee">
							<ul data-v-6a96ae2b="" class="marquee__content">
								{coinData && coinData.map((coin, key) => (
									<li data-v-6a96ae2b="" class="item border-right">
										<div data-v-6a96ae2b="" class="symbol fn-16 mb-2">{coin.symbol.toUpperCase()}/USDT</div>
										<div data-v-6a96ae2b="" class="d-flex justify-content-between">
											<span data-v-6a96ae2b="">{formatNum(coin.price, { minFraction: 2, maxFraction: 2 })}</span>
											<span data-v-6a96ae2b="" class={`${coin.percent_1h < 0 ? 'colorRed' : 'colorGreen'}`}>{formatNum(coin.percent_1h, { minFraction: 2, maxFraction: 2 })}% <i data-v-6a96ae2b="" class={`${coin.percent_1h < 0 ? 'el-icon-caret-bottom' : 'el-icon-caret-top'}`}></i>
											</span>
										</div>
									</li>
								))}
							</ul>

							<ul aria-hidden="true" class="marquee__content">
								{coinData && coinData.map((coin, key) => (
									<li data-v-6a96ae2b="" class="item border-right">
										<div data-v-6a96ae2b="" class="symbol fn-16 mb-2">{coin.symbol.toUpperCase()}/USDT</div>
										<div data-v-6a96ae2b="" class="d-flex justify-content-between">
											<span data-v-6a96ae2b="">{formatNum(coin.price, { minFraction: 2, maxFraction: 2 })}</span>
											<span data-v-6a96ae2b="" class={`${coin.percent_1h < 0 ? 'colorRed' : 'colorGreen'}`}>{formatNum(coin.percent_1h, { minFraction: 2, maxFraction: 2 })}% <i data-v-6a96ae2b="" class={`${coin.percent_1h < 0 ? 'el-icon-caret-bottom' : 'el-icon-caret-top'}`}></i>
											</span>
										</div>
									</li>
								))}
							</ul>
						</div>}
						<section data-v-5954443c="" className="platform">
							<div data-v-5954443c="" className="container h-100">
								<div data-v-5954443c="" className="row h-100">
									<div data-v-5954443c="" className="col-lg-5 mb-lg-0 mb-3">
										<div data-v-5954443c="" className=" d-flex flex-column justify-content-center align-items-lg-start align-items-center h-100 pr-lg-5">
											<p data-v-5954443c="" className="wbo-title titleLarge">{t('platform.title')}</p>
											<p data-v-5954443c="" className="font-18 color-white">{t('platform.subtitle')}</p>
											{/* <a data-v-5954443c="" href="#" onClick={navigateTo('trading').bind(this)} className="button wbtn height-60 btn-large btn-radius font-20 w-75 mb-lg-0 mb-4 font-16m">Bắt đầu giao dịch</a> */}
										</div>
									</div>
									<div data-v-5954443c="" className="col-lg-6 offset-lg-1">
										<div data-v-5954443c="" className="row align-items-center h-100">
											<div data-v-5954443c="" className="col-6 mb30pc mb16px pointer" onClick={navigateTo('deposit').bind(this)}>
												<div data-v-5954443c="" className=" platformBox d-flex flex-column">
													<div data-v-5954443c="" className="topContent mb-5">
														<img width="200" height="200" src="images/icon/bank-building.png" alt="bank-building" />
													</div>
													<div data-v-5954443c="" className="bottomContent">
														<p data-v-5954443c="" className="wbo-title titleSmall color-white font-weight-700 text-uppercase"> {t('common.recharge')}</p>
													</div>
												</div>
											</div>
											<div data-v-5954443c="" className="col-6 mb30pc mb16px pointer" onClick={navigateTo('wallet').bind(this)}>
												<div data-v-5954443c="" className=" platformBox d-flex flex-column">
													<div data-v-5954443c="" className="topContent mb-5">
														<img width="200" height="200" src="images/icon/money-bag.png" alt="money-bag" />
													</div>
													<div data-v-5954443c="" className="bottomContent">
														<p data-v-5954443c="" className="wbo-title titleSmall color-white font-weight-700 text-uppercase"> {t('common.wallet')}</p>
													</div>
												</div>
											</div>
											<div data-v-5954443c="" className="col-6 mb30pc mb16px pointer" onClick={() => window.location.href = cskh_link}>
												<div data-v-5954443c="" className=" platformBox d-flex flex-column">
													<div data-v-5954443c="" className="topContent mb-5">
														<img width="200" height="200" src="images/icon/short-hair-girl-question-mark.png" alt="short-hair-girl-question-mark" />
													</div>
													<div data-v-5954443c="" className="bottomContent">
														<p data-v-5954443c="" className="wbo-title titleSmall color-white font-weight-700 text-uppercase"> {t('common.support')}</p>
													</div>
												</div>
											</div>
											<div data-v-5954443c="" className="col-6 mb30pc mb16px pointer" onClick={navigateTo('trading').bind(this)}>
												<div data-v-5954443c="" className=" platformBox d-flex flex-column">
													<div data-v-5954443c="" className="topContent mb-5">
														<img width="200" height="200" src="images/icon/bitcoin.png" alt="bitcoin" />
													</div>
													<div data-v-5954443c="" className="bottomContent">
														<p data-v-5954443c="" className="wbo-title titleSmall color-white font-weight-700 text-uppercase"> {t('common.start_trading')}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section data-v-5954443c="" className='market_price mt-5'>
							<div className='container h-100'>
								<p data-v-5954443c="" className="wbo-title titleLarge mb-4">{t('market_price.updated_prices_from_the_market')}</p>
								<div class="table-responsive">
									{coinData.length > 0 && <div className='col-lg-12 chart-tooltip-header'>
										<table class="table tableBackground table-dark" style={{ fontSize: '15px' }}>
											<thead className='text-center'>
												<tr>
													<th scope="col">#</th>
													<th scope="col" className='text-left'>{t('market_price.name')}</th>
													<th scope="col">{t('market_price.pricce')}</th>
													<th scope="col">{t('market_price.price_fluctuations_24_hours')}</th>
													<th scope="col">{t('market_price.highest_price_24_hours')}</th>
													<th scope="col">{t('market_price.lowest_price_24_hours')}</th>
													<th scope="col">{t('market_price.trading_volume')}</th>
												</tr>
											</thead>
											<tbody>
												{coinData && coinData.map((item, key) => (
													<tr className='pointer' key={key} onClick={() => window.location.href = 'trading?symbol=' + item.name.toUpperCase()}>
														<th scope="row" className='align-middle'><i class="fa fa-star" aria-hidden="true"></i></th>
														<td><img src={item.icon} width={30} height={30} /> {item.name.toUpperCase().replace('USDT', '')} ({item.symbol.toUpperCase()})</td>
														<td className='text-center colorGreen'>{formatNum(item.price, { minFraction: 2, maxFraction: 2 })}$</td>
														<td className='text-center'><span class={`badge p-2 ${item.percent_1h < 0 ? 'badge-danger' : 'bgGreen'}`} style={{ fontSize: '14px' }}>{formatNum(item.percent_1h, { minFraction: 2, maxFraction: 2 })}%</span></td>
														<td className='text-center'><span class={`${item.percent_1h < 0 ? 'text-danger' : 'colorGreen'}`}>{formatNum(item.high_24h, { minFraction: 2, maxFraction: 2 })}</span></td>
														<td className='text-center'><span class={`${item.percent_1h < 0 ? 'text-danger' : 'colorGreen'}`}>{formatNum(item.low_24h, { minFraction: 2, maxFraction: 2 })}</span></td>
														<td className='text-center colorGreen'>{formatNum(item.volume, { minFraction: 0, maxFraction: 2 })}$</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>}
								</div>
							</div>
						</section>
						<section data-v-5954443c="" className="theFeatures">
							<div data-v-5954443c="" className="container">
								<p data-v-5954443c="" className="wbo-title titleLarge mb-4">{t('features.title')} {seo_title} </p>
								<div data-v-5954443c="" className="row">
									<div data-v-5954443c="" className="col-lg-3 col-6">
										<div data-v-5954443c="" className="backgroundImg background-1 mb30pc mb16mobile">
											<div data-v-5954443c="" className="contentFeature">
												<p data-v-5954443c="" className="wbo-title titleSmall"> {t('features.title_block_one')}</p>
												<p data-v-5954443c="" className="text-elips-f14-4l">{t('features.subtitle_block_one')}</p>
											</div>
										</div>
										<div data-v-5954443c="" className="backgroundImg background-2">
											<div data-v-5954443c="" className="contentFeature">
												<p data-v-5954443c="" className="wbo-title titleSmall"> {t('features.title_block_two')}</p>
												<p data-v-5954443c="" className="text-elips-f14-4l">{t('features.subtitle_block_two')}</p>
											</div>
										</div>
									</div>
									<div data-v-5954443c="" className="col-lg-3 col-6">
										<div data-v-5954443c="" className="backgroundImg background-3">
											<div data-v-5954443c="" className="contentFeature positionTop">
												<p data-v-5954443c="" className="wbo-title titleSmall">{t('features.title_block_three')}</p>
												<p data-v-5954443c="">{t('features.subtitle_block_three')}</p>
											</div>
										</div>
									</div>
									<div data-v-5954443c="" className="col-lg-6">
										<div data-v-5954443c="" style={{ marginTop: isDesktop() ? '' : '1rem' }} className="backgroundImg background-4 mb30pc mb16mobile">
											<div data-v-5954443c="" className="contentFeature">
												<p data-v-5954443c="" className="wbo-title titleSmall"> {t('features.title_block_four')}</p>
												<p data-v-5954443c="">{t('features.subtitle_block_four')}</p>
											</div>
										</div>
										<div data-v-5954443c="" className="backgroundImg background-5">
											<div data-v-5954443c="" className="contentFeature">
												<p data-v-5954443c="" className="wbo-title titleSmall"> {t('features.title_block_five')}</p>
												<p data-v-5954443c="">{t('features.subtitle_block_five')}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>

						<section data-v-5954443c="" className="peopleSay">
							<div data-v-5954443c="" className="container position-relative">
								<p data-v-5954443c="" className="wbo-title titleLarge mb-4"> {t('peoplesay.title')}</p>
								<div data-v-5954443c="" className="row">
									<div data-v-5954443c="" className="col-lg-7">
										<div data-v-5954443c="" className="sayBox topBox">
											<div data-v-5954443c="" className="swiper-container peopleSaySwiper swiper-container-fade swiper-container-vertical">
												<div data-v-5954443c="" className="swiper-wrapper">
													<div data-v-5954443c="" className="swiper-slide swiper-slide-active" style={{ height: '239px', opacity: 1, transform: 'translate3d(0px, 0px, 0px)' }}>
														<div data-v-5954443c="" className="contentItem top-left">
															<div data-v-5954443c="" className="textBox">
																<p data-v-5954443c="" className="wbo-title titleSmall color-white text-uppercase font-weight-700"> {t('peoplesay.name_1')}</p>
																<p data-v-5954443c="" className="color-white-50 text-elips-f14-4l">“{t('peoplesay.comment_1', { seo_title })}”</p>
															</div>
															<div data-v-5954443c="" className="avatar">
																<div data-v-5954443c="" className=" backgroundImg backgroundTopLeft" />
															</div>
														</div>
													</div>
												</div>
												<span className="swiper-notification" aria-live="assertive" aria-atomic="true" />
											</div>
										</div>
										<div data-v-5954443c="" className="sayBox bottomBox">
											<div data-v-5954443c="" className="swiper-container peopleSaySwiper swiper-container-fade swiper-container-vertical">
												<div data-v-5954443c="" className="swiper-wrapper">
													<div data-v-5954443c="" className="swiper-slide swiper-slide-active" style={{ height: '239px', opacity: 1, transform: 'translate3d(0px, 0px, 0px)' }}>
														<div data-v-5954443c="" className="contentItem bottom-left">
															<div data-v-5954443c="" className="avatar">
																<div data-v-5954443c="" className=" backgroundImg backgroundBottomLeft" />
															</div>
															<div data-v-5954443c="" className="textBox">
																<p data-v-5954443c="" className="wbo-title titleSmall color-white text-uppercase font-weight-700"> {t('peoplesay.name_2')}</p>
																<p data-v-5954443c="" className="color-white-50 text-elips-f14-4l">“{t('peoplesay.comment_2', { seo_title })}”</p>
															</div>
														</div>
													</div>
												</div>
												<span className="swiper-notification" aria-live="assertive" aria-atomic="true" />
											</div>
										</div>
									</div>
									<div data-v-5954443c="" className="col-lg-5 mt1-landscape">
										<div data-v-5954443c="" className="sayBox rightBox">
											<div data-v-5954443c="" className="swiper-container peopleSaySwiper swiper-container-fade swiper-container-vertical">
												<div data-v-5954443c="" className="swiper-wrapper">
													<div data-v-5954443c="" className="swiper-slide swiper-slide-active" style={{ height: '495px', opacity: 1, transform: 'translate3d(0px, 0px, 0px)' }}>
														<div data-v-5954443c="" className="contentItem right">
															<div data-v-5954443c="" className="avatar">
																<div data-v-5954443c="" className=" backgroundImg backgroundRight" />
															</div>
															<div data-v-5954443c="" className="textBox">
																<p data-v-5954443c="" className="wbo-title titleSmall color-white text-uppercase font-weight-700"> {t('peoplesay.name_3')}</p>
																<p data-v-5954443c="" className="color-white-50 text-elips-f14-4l">“{t('peoplesay.comment_3', { seo_title })}”.</p>
															</div>
														</div>
													</div>
												</div>
												<span className="swiper-notification" aria-live="assertive" aria-atomic="true" />
											</div>
										</div>
									</div>
								</div>
								<div data-v-5954443c="" className="groupButton text-center py-4 bg-dot">
									<a data-v-5954443c="" href="#" onClick={navigateTo('trading').bind(this)} className="button wbtn height-60 btn-large btn-radius font-20 w-25 font-16m bgSecondaryBtn3 mt-5">{t('common.start_trading')}</a>
								</div>
							</div>
						</section>
						<div data-v-24978cff="" data-v-5954443c="" className="footerFAQs">
							<div data-v-24978cff="" className="container position-relative">
								<div data-v-24978cff="" className="blockRow py-5">
									<div data-v-24978cff="" className="block logo">
										<p data-v-24978cff="">
											<img data-v-24978cff="" width="208" height="35" src={asset_logo} alt="" />
										</p>
										<p data-v-24978cff="" className="font-12  text-white">© {new Date().getFullYear()} {seo_title} {t('footer.copyright')}</p>
									</div>
									<div data-v-24978cff="" className="block  text-white">
										<p data-v-24978cff="">{t('footer.warning')}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default connect(mapStateToProps)(withTranslation()(withRouter(Home)));
