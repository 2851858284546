import ax from './_ax';
import * as auth from './auth';
import * as member from './member';
import * as trading from './trading';
import * as base from './base';
import * as dashboard from './dashboard';
import * as challenge from './challenge';
import * as notification from './notification';
import * as home from './home';

export default {
	ax,
	...auth,
	...member,
	...trading,
	...base,
	...dashboard,
	...challenge,
	...notification,
	...home
};
