import { Component, createRef } from 'react';
import { connect } from "react-redux";
import {
	formatNumber,
	isCurrentRoute,
	navigateTo,
	showNotification,
	handleClickOutside,
	isDesktop,
	isMobile,
	setClassNameCondition
} from '../lib/helper';
import { withRouter } from 'react-router-dom';
import { fetchingUser, fetchingUserSuccess, updateAppConfigAction, fetchingTransactionAction, fetchingNotification, logoutUser } from "../redux/actions";
import api from "../lib/api";
import constants from "../lib/constants";
import Deposit from "./trading/partial/Deposit";
import TransactionBox from "../lib/custom/TransactionBox";
import Notification from "../lib/custom/Notification";
import { withTranslation } from 'react-i18next';
import LanguageSelector from '../components/LanguageSelector'

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			is_show_dropdown: false,
			is_show_helper: false,
			is_show_setting: false,
			is_show_notification: false,
			is_show_profile: false,

			enable_sound: 1,
			is_show_balance: 1
		};
		this.refList = {
			is_show_dropdown: createRef(),
			is_show_helper: createRef(),
			is_show_setting: createRef(),
			is_show_profile: createRef(),
		};
	}

	componentDidMount() {
		let { enable_sound, is_show_balance } = this.props.memberReducer || {};
		this.setState({ enable_sound, is_show_balance });
		document.addEventListener('click', handleClickOutside.bind(this));
		this.props.fetchingNotification();
	}

	componentWillUnmount() {
		document.removeEventListener('click', handleClickOutside.bind(this))
	}

	onChangePointType = (point_type) => () => {
		api.putChangeProfile({ current_point_type: point_type })
			.then(({ data, message, success }) => {
				if (success) {
					this.props.fetchingUserSuccess(data);
				} else {
					showNotification({ message, type: 'danger' });
				}
			});
		this.onChangeState('is_show_dropdown')();
	}

	onResetDemoBalance = (e) => {
		e.preventDefault();
		api.resetDemoBalance().then(({ data, success, message }) => {
			if (success) {
				this.props.fetchingUserSuccess(data);
			}
			showNotification({ message, type: success ? 'success' : 'danger' });
			this.onChangeState('is_show_dropdown')();
		});
	}

	onClickChangeSetting = (type) => () => {
		let value = this.state[type] === 1 ? 0 : 1;
		this.setState({ [type]: value }, () => {
			api.putChangeProfile({ [type]: value })
				.then(({ data, success, message }) => {
					if (success) {
						return this.props.fetchingUserSuccess(data);
					}
					showNotification({ message, type: success ? 'success' : 'danger' });
				});
		});
	}

	onToggle = (type) => (e) => {
		console.log(type);
		e.preventDefault();
		this.props.updateAppConfigAction({ [type]: !this.props.appReducer[type] });
		if (type === 'is_show_transaction') {
			this.props.fetchingTransactionAction();
		}
	}

	onChangeState = (type) => (e) => {
		e && e.preventDefault();
		this.setState({ [type]: !this.state[type] });
	}

	onReadAllNotification = () => {
		api.putMarkReadAllNotification().then(() => this.props.fetchingNotification());
	}

	onViewAllNotification = () => {
		this.setState({ is_show_notification: false });
		navigateTo('notification').bind(this)();
	}

	onLogout = (e) => {
		e.preventDefault();
		this.props.logoutUser();
		const chatWidgetContainer = document.getElementById('chat-widget-container');
		if (chatWidgetContainer) {
			chatWidgetContainer.style.display = 'none';
		}
		this.props.history.push('/');
	}

	render() {
		let { login_token, demo_balance, real_balance, current_point_type, verified_point, is_verify } = this.props.memberReducer || {};
		let balance = this.props.memberReducer[`${current_point_type}_balance`];
		let { is_show_dropdown, is_show_helper, is_show_setting, enable_sound, is_show_balance, is_show_notification, is_show_profile } = this.state;
		let { phone, is_show_transaction, is_show_menu, asset_logo } = this.props.appReducer;
		let { un_read_count, notification } = this.props.notificationReducer;
		let { total_bet_open } = this.props.transactionReducer;
		const { t } = this.props;

		return <div data-v-bd82969a="" data-v-b0e45e8c="" className="headerMaster">
			<div data-v-bd82969a="" className="boxHeader navbar fixed-top">
				<div data-v-bd82969a="" className={'headerWapper bgSecondary3'}>
					<header data-v-bd82969a="" className="d-flex justify-content-between align-items-center w-100">
						<div data-v-bd82969a="" id="leftNav">
							{isMobile() && <div data-v-bd82969a="" className="mobile menuMobileIcon">
								<span data-v-bd82969a=""
									onClick={this.onToggle('is_show_menu')}
									className={'mobileIcon ' + (is_show_menu ? 'show' : 'hide')}><svg
										data-v-bd82969a="" xmlns="http://www.w3.org/2000/svg" width="23.944" height="19.953"
										viewBox="0 0 23.944 19.953"><g data-v-bd82969a="" id="menu-4"
											transform="translate(0 -1)"><path data-v-bd82969a=""
												id="Path_29261"
												data-name="Path 29261"
												d="M21.949,5H2A2,2,0,0,0,2,8.991H21.949a2,2,0,1,0,0-3.991Z"
												transform="translate(0 3.981)"
												fill="#fff" /> <path
												data-v-bd82969a="" id="Path_29262" data-name="Path 29262"
												d="M21.949,1H2A2,2,0,0,0,2,4.991H21.949a2,2,0,1,0,0-3.991Z" fill="#fff" /> <path
												data-v-bd82969a="" id="Path_29263" data-name="Path 29263"
												d="M9.977,9H2a2,2,0,0,0,0,3.991H9.977A2,2,0,1,0,9.977,9Z" transform="translate(0 7.963)"
												fill="#fff" /></g></svg></span>
							</div>}
							<a data-v-bd82969a="" href="#" onClick={navigateTo().bind(this)} className="logoMobile router-link-exact-active router-link-active">
								{(isCurrentRoute(constants.ROUTE.HOME) || isDesktop()) && <img data-v-bd82969a="" width={isDesktop() ? 208 : 151} height="35"
									src={asset_logo}
									alt="" />}
							</a>
							{isMobile() && !isCurrentRoute(constants.ROUTE.HOME) && <Deposit />}
						</div>
						<div data-v-bd82969a="" id="rightNav">
							{login_token ?
								(
									<ul data-v-bd82969a="" className="nav infoMem align-items-center">
										<li data-v-bd82969a="" className="settings"><LanguageSelector /></li>
										<li data-v-bd82969a="" className="balance border-radius-10" ref={this.refList.is_show_dropdown}>
											<div data-v-bd82969a="" className={'dropdown pointer ' + (is_show_dropdown ? 'show' : '')}>
												<a data-v-bd82969a="" role="button" data-toggle="dropdown"
													aria-haspopup="true" aria-expanded={is_show_dropdown}
													// onClick={this.onChangeState('is_show_dropdown')}
													className="buttonBalance d-flex align-items-center">
													<div data-v-bd82969a="" className="d-flex flex-column mr-lg-2 mr-2"><span
														data-v-bd82969a=""
														className="font-10 text-left color-white mb-1">{t('common.account')} </span>
														<div data-v-bd82969a=""
															className="d-flex align-items-center"><span
																data-v-bd82969a="" className="colorWhite font-18"><b
																	data-v-bd82969a="">${formatNumber(balance)}</b></span></div>
													</div>
													<span data-v-bd82969a="" className="arrow">
														<svg data-v-a55cfb82="" xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 32 32"><g data-v-a55cfb82="" strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="#111111" stroke="#111111" className="nc-icon-wrapper"><g data-v-a55cfb82="" className="nc-interact_sorting-o-32"><path data-v-a55cfb82="" data-cap="none" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" d="M4 10l12 12" strokeDasharray="16.97 16.97" strokeDashoffset="0"></path><path data-v-a55cfb82="" data-cap="none" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" d="M28 10L16 22" strokeDasharray="16.97 16.97" strokeDashoffset="0"></path></g></g></svg>
													</span>
												</a>
											</div>
										</li>
										{isDesktop() && <Deposit />}
										<li data-v-bd82969a="" className="settings">
											<a data-v-bd82969a=""
												href="#"
												onClick={navigateTo('dashboard').bind(this)}
												className={setClassNameCondition(isCurrentRoute(constants.ROUTE.DASHBOARD), 'router-link-exact-active active', '', 'nav-link p-0')}
												title="Bảng điều khiển">
												<div data-v-bd82969a="" className="buttonSetting d-block align-items-center pointer pc">
													<span data-v-bd82969a="" className="icon">
														<svg data-v-bd82969a=""
															id="speedometer"
															xmlns="http://www.w3.org/2000/svg"
															width="17.809"
															height="17.809"
															viewBox="0 0 17.809 17.809"
															className={isCurrentRoute(constants.ROUTE.DASHBOARD) ? 'active' : 'non-active'}><path
																data-v-bd82969a="" id="Path_30761" data-name="Path 30761"
																d="M9.995,9.995a2.226,2.226,0,0,0,0-3.149C9.125,5.978,1,1,1,1S5.978,9.125,6.846,9.995A2.226,2.226,0,0,0,9.995,9.995Z"
																transform="translate(0.484 0.484)" fill={isCurrentRoute(constants.ROUTE.DASHBOARD) ? '#2076fe' : '#89809f'} /> <path
																data-v-bd82969a="" id="Path_30762" data-name="Path 30762"
																d="M8.9,0a1.484,1.484,0,1,0,0,2.968A5.936,5.936,0,1,1,2.968,8.9,1.484,1.484,0,1,0,0,8.9,8.9,8.9,0,1,0,8.9,0Z"
																fill={isCurrentRoute(constants.ROUTE.DASHBOARD) ? '#2076fe' : '#89809f'} /></svg>
													</span>
													<span data-v-bd82969a="" className="font-14 txtProfile">{t('common.transaction_history')}</span>
												</div>
											</a>
										</li>
										<li data-v-bd82969a="" ref={this.refList.is_show_setting} className="settings">
											<div data-v-bd82969a=""
												onClick={this.onChangeState('is_show_setting')}
												className="buttonSetting d-block align-items-center pointer pc">
												<span data-v-bd82969a="" className="icon"><svg
													data-v-bd82969a="" xmlns="http://www.w3.org/2000/svg"
													width="17.281" height="17.283"
													viewBox="0 0 17.281 17.283"><g data-v-bd82969a=""
														id="gear"
														transform="translate(-2.004 -1.995)"><path
															data-v-bd82969a="" id="Path_30727"
															data-name="Path 30727"
															d="M18.111,9.362,16.772,9.25a6.245,6.245,0,0,0-.814-1.96l.871-1.03a1.28,1.28,0,0,0-1.8-1.8L14,5.326a6.234,6.234,0,0,0-1.963-.816L11.92,3.17a1.28,1.28,0,0,0-2.552,0L9.256,4.51a6.245,6.245,0,0,0-1.96.814l-1.03-.868a1.28,1.28,0,0,0-1.8,1.8l.871,1.029a6.239,6.239,0,0,0-.816,1.961l-1.341.112a1.281,1.281,0,0,0,0,2.552l1.341.112a6.238,6.238,0,0,0,.814,1.96l-.871,1.03a1.28,1.28,0,0,0,1.8,1.8L7.3,15.95a6.239,6.239,0,0,0,1.961.816l.112,1.341a1.281,1.281,0,0,0,2.552,0l.112-1.341a6.238,6.238,0,0,0,1.96-.814l1.03.871a1.28,1.28,0,0,0,1.8-1.8l-.871-1.029a6.233,6.233,0,0,0,.816-1.961l1.341-.112a1.281,1.281,0,0,0,0-2.552Zm-7.467,4.445a3.168,3.168,0,1,1,3.168-3.168A3.168,3.168,0,0,1,10.644,13.806Z"
															fill="#89809f" /></g></svg></span> <span
																data-v-bd82969a="">{t('common.setting')}</span>
											</div>
											{is_show_setting && <div data-v-c80df956="" data-v-bd82969a="">
												<div data-v-c80df956="" className="settings">
													<div data-v-c80df956=""
														className="dropdown-menu dropdownSetting d-flex flex-column show">
														<div data-v-c80df956="" className="wrap">
															<div data-v-c80df956="" className="line mb-3">
																<div data-v-c80df956="" className="type">
																	<svg data-v-c80df956="" version="1.1" width="19" height="19" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 19 19" xmlSpace="preserve"><g data-v-c80df956="" id="sound" transform="translate(0.5 -0.5)"><g data-v-c80df956="" id="Path_30736" fill="#887F9E"><path data-v-c80df956="" d="M13.7,19.3c-0.2,0-0.4-0.1-0.6-0.2l-6.9-4.6H0.5c-0.6,0-1-0.4-1-1V6.3c0-0.6,0.4-1,1-1h5.7l6.9-4.6
                                        c0.3-0.2,0.7-0.2,1,0c0.3,0.2,0.5,0.5,0.5,0.9v16.8c0,0.4-0.2,0.7-0.5,0.9C14,19.3,13.9,19.3,13.7,19.3z M1.5,12.5h5
                                        c0.2,0,0.4,0.1,0.6,0.2l5.6,3.8V3.4L7.1,7.1C6.9,7.2,6.7,7.3,6.5,7.3h-5V12.5z" className="st0" /></g> <g
																			data-v-c80df956="" id="Path_30737" fill="#887F9E"><path data-v-c80df956="" d="M16.8,15.1c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0c1.9-2,1.9-5.1-0.1-7
                                        c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0c2.8,2.7,2.8,7.1,0.1,9.9c0,0-0.1,0.1-0.1,0.1C17.2,15,17,15.1,16.8,15.1z" className="st0" /></g></g></svg>
																	<span data-v-c80df956=""
																		className="colorWhite">{t('common.sound')}</span></div>
																<div data-v-c80df956="" className="control">
																	<div data-v-272fdeda="" data-v-c80df956=""
																		className="toggleButtonWrapper"><span
																			data-v-272fdeda="" className="colorWhite">Off</span>
																		<div data-v-272fdeda="" onClick={this.onClickChangeSetting('enable_sound')} className={'toggleButton pointer isButtonOnOff ' + (enable_sound ? 'hasBg' : '')}>
																			<div data-v-272fdeda="" className={'status ' + (enable_sound ? 'yes' : '')} />
																		</div>
																		<span data-v-272fdeda="" className="colorWhite">On</span></div>
																</div>
															</div>
															<div data-v-c80df956="" className="line">
																<div data-v-c80df956="" className="type">
																	<svg data-v-c80df956=""
																		xmlns="http://www.w3.org/2000/svg" width="18"
																		height="13.543" viewBox="0 0 18 13.543">
																		<g data-v-c80df956="" id="preview"
																			transform="translate(-734.025 -2)" opacity="0.5">
																			<path data-v-c80df956="" id="Path_30402"
																				data-name="Path 30402"
																				d="M9.025,15.543c4.063,0,7.223-3.5,8.577-5.53a2.2,2.2,0,0,0,0-2.6C16.248,5.5,13.088,2,9.025,2S1.8,5.5.448,7.53a2.031,2.031,0,0,0,0,2.483C1.8,12.044,4.962,15.543,9.025,15.543Zm0-10.157a3.325,3.325,0,0,1,3.386,3.386,3.325,3.325,0,0,1-3.386,3.386A3.325,3.325,0,0,1,5.64,8.771,3.325,3.325,0,0,1,9.025,5.386Z"
																				transform="translate(734)"
																				fill="#fff" />
																		</g>
																	</svg>
																	<span data-v-c80df956="" className="colorWhite">{t('common.show_balance')}
																	</span></div>
																<div data-v-c80df956="" className="control">
																	<div data-v-272fdeda="" data-v-c80df956=""
																		className="toggleButtonWrapper"><span
																			data-v-272fdeda="" className="colorWhite">Off</span>
																		<div data-v-272fdeda=""
																			onClick={this.onClickChangeSetting('is_show_balance')}
																			className={'toggleButton pointer isButtonOnOff ' + (is_show_balance ? 'hasBg' : '')}>
																			<div data-v-272fdeda=""
																				className={'status ' + (is_show_balance ? 'yes' : '')} />
																		</div>
																		<span data-v-272fdeda=""
																			className="colorWhite">On</span></div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>}
										</li>
										<li data-v-bd82969a="" ref={this.refList.is_show_profile} className="settings" onClick={this.onChangeState('is_show_profile')}>
											<div data-v-bd82969a=""
												className="buttonSetting d-block align-items-center pointer pc">
												<span data-v-bd82969a="" className="icon"><svg
													data-v-bd82969a="" xmlns="http://www.w3.org/2000/svg"
													width="17.281" height="17.283" viewBox="0 0 24 24"><g
														data-v-bd82969a="" id="circle-10"
														transform="translate(-1.164 -1.164)"><path
															data-v-bd82969a="" id="Path_29003"
															data-name="Path 29003"
															d="M33.642,36.066V35.3a3.093,3.093,0,0,0-1.558-2.685l-3.078-1.765"
															transform="translate(-13.086 -13.978)" fill="none"
															stroke="#89809f" strokeMiterlimit="10"
															strokeWidth="2" /> <path data-v-bd82969a=""
																id="Path_29004"
																data-name="Path 29004"
																d="M14.636,30.847l-3.078,1.765A3.093,3.093,0,0,0,10,35.3v.768"
																transform="translate(-3.876 -13.978)"
																fill="none"
																stroke="#89809f"
																strokeMiterlimit="10"
																strokeWidth="2" /> <path
															data-v-bd82969a="" id="Path_29005"
															data-name="Path 29005"
															d="M20.124,22.824h0c-2.277,0-4.124-2.362-4.124-4.639V16.124A4.124,4.124,0,0,1,20.124,12h0a4.124,4.124,0,0,1,4.124,4.124v2.062C24.247,20.463,22.4,22.824,20.124,22.824Z"
															transform="translate(-6.784 -4.846)" fill="none"
															stroke="#89809f" strokeLinecap="square"
															strokeMiterlimit="10" strokeWidth="2" /> <circle
															data-v-bd82969a="" id="Ellipse_1365"
															data-name="Ellipse 1365" cx="11" cy="11" r="11"
															transform="translate(2.164 2.164)" fill="none"
															stroke="#89809f" strokeLinecap="square"
															strokeMiterlimit="10"
															strokeWidth="2" /></g></svg></span> <span
																data-v-bd82969a=""
																className="font-14 txtProfile">{t('common.profile')}</span></div>
											{is_show_profile && <div data-v-c80df956="" data-v-bd82969a="">
												<div data-v-c80df956="" className="settings">
													<div data-v-c80df956=""
														className="dropdown-menu dropdownSetting d-flex flex-column show">
														<div data-v-c80df956="" className="wrap">
															<div data-v-c80df956="" className="line mb-3">
																<div data-v-c80df956="" className="type">

																	<span data-v-c80df956=""
																		className="colorWhite mr-2"><i className="fa fa-bullhorn colorYellow" aria-hidden="true"></i> {t('common.status')}: </span>{is_verify == 1 ? <span className='colorGreen font-14'><i className="fa fa-check-circle" aria-hidden="true"></i> {t('common.verified')}</span> : <span className='colorRed font-14'><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {t('common.not_verified')}</span>}</div>
															</div>
															<div data-v-c80df956="" className="line mb-3">
																<div data-v-c80df956="" className="type">

																	<span data-v-c80df956=""
																		className="colorWhite mr-2"><i className="fa fa-address-card colorYellow" aria-hidden="true"></i> {t('common.credit_score')}: </span><span className={verified_point < 100 ? 'colorYellow' : 'colorGreen'}>{is_verify == 1 ? verified_point : 0}</span></div>
															</div>
															<div data-v-c80df956="" className="line mb-3 pointer" onClick={navigateTo('profile').bind(this)}>
																<div data-v-c80df956="" className="type">

																	<span data-v-c80df956=""
																		className="colorWhite"><i className="fa fa-user-circle colorYellow" aria-hidden="true"></i>  {t('common.personal_information')}</span></div>
															</div>
															<div data-v-c80df956="" className="line mb-3 pointer" onClick={this.onLogout}>
																<div data-v-c80df956="" className="type">

																	<span data-v-c80df956=""
																		className="colorWhite"><i className="fa fa-power-off colorYellow" aria-hidden="true"></i>  {t('common.logout')}</span></div>
															</div>
														</div>
													</div>
												</div>
											</div>}
										</li>
										{isDesktop() && <li data-v-bd82969a="" className="settings">
											<div data-v-bd82969a="" className="notification-dropdown">
												<div className="notification-dropdown-button" onClick={this.onChangeState('is_show_notification')}>
													<span className="notification-dropdown-button-icon">
														<svg xmlns="http://www.w3.org/2000/svg" width="16.545"
															height="18" viewBox="0 0 16.545 18" className="2"><g
																id="bell" transform="translate(0.5 0.5)"><path
																	id="Path_35098" data-name="Path 35098"
																	d="M18.393,11.572A3.536,3.536,0,0,1,17.24,8.963V6.712A5.884,5.884,0,0,0,11.472,1,5.913,5.913,0,0,0,5.7,6.767v2.2a3.536,3.536,0,0,1-1.153,2.609A2.488,2.488,0,0,0,3.7,13.247c0,1.51,3.131,2.526,7.772,2.526s7.772-1.016,7.772-2.526A2.547,2.547,0,0,0,18.393,11.572Z"
																	transform="translate(-3.7 -1)" fill="none" stroke="#fff"
																	strokeWidth="1" /> <path id="Path_35099"
																		data-name="Path 35099"
																		d="M25.619,56.51A22.364,22.364,0,0,1,23.2,56.4a2.491,2.491,0,0,0,2.419,1.787A2.524,2.524,0,0,0,28.038,56.4,22.364,22.364,0,0,1,25.619,56.51Z"
																		transform="translate(-17.847 -41.187)"
																		fill="none" stroke="#fff"
																		strokeWidth="1" /></g></svg>
													</span>
													<span className="notification-dropdown-button-text">{t('common.notification')}</span>
													{un_read_count > 0 && <span className='notification-dropdown-button-number'>{un_read_count}</span>}
												</div>
												{is_show_notification && <>
													<div className="mask-close" onClick={this.onChangeState('is_show_notification')} />
													<div className="notification-dropdown-body">
														<div className="notification-dropdown-header">
															<h3 className="notification-dropdown-title">
																{t('common.notification')}
																<span onClick={this.onReadAllNotification} className="notification-dropdown-readall"><span><svg
																	xmlns="http://www.w3.org/2000/svg" width="38"
																	height="21" viewBox="0 0 38 21"><g id="Group_21335"
																		data-name="Group 21335"
																		transform="translate(-917 -81)"><rect
																			id="Rectangle_4940" data-name="Rectangle 4940"
																			width="38" height="21" rx="3"
																			transform="translate(917 81)" fill="#878098" /> <g
																				id="check-all" transform="translate(924.785 83.931)"><path
																				id="Path_35112" data-name="Path 35112"
																				d="M4.773,18.9,0,14.123l1.3-1.3,3.91,2.607L14.336,5l1.3.652L6.757,18.727a1.3,1.3,0,0,1-1.985.168Z"
																				transform="translate(0 -5)" fill="#fefefe" /> <path
																				id="Path_35113" data-name="Path 35113"
																				d="M28.91,10.3H25.652a.652.652,0,1,1,0-1.3H28.91a.652.652,0,1,1,0,1.3Z"
																				transform="translate(-8.709 -6.393)"
																				fill="#fefefe" /> <path id="Path_35114"
																					data-name="Path 35114"
																					d="M27.516,17.3H21.652a.652.652,0,0,1,0-1.3h5.865a.652.652,0,1,1,0,1.3Z"
																					transform="translate(-7.316 -8.832)"
																					fill="#fefefe" /> <path
																				id="Path_35115" data-name="Path 35115"
																				d="M25.774,24.3H16.652a.652.652,0,0,1,0-1.3h9.123a.652.652,0,0,1,0,1.3Z"
																				transform="translate(-5.574 -11.271)"
																				fill="#fefefe" /></g></g></svg></span> </span>
															</h3>
															<span className="notification-dropdown-view-all" onClick={this.onViewAllNotification}>{t('common.see_all')}</span>
															<span className="notification-dropdown-close pe pe-7s-close" />
														</div>
														<div className="notification-dropdown-list">
															<Notification list={notification} />
														</div>
													</div>
												</>}
											</div>
										</li>}

										{isDesktop() && <li data-v-bd82969a=""><span data-v-bd82969a="" className="line" /></li>}

										{isCurrentRoute(constants.ROUTE.TRADING) && isDesktop() && <li data-v-bd82969a="">
											<div data-v-bd82969a="" onClick={this.onToggle('is_show_transaction')} className="buttonToggle pointer">
												<svg data-v-bd82969a="" xmlns="http://www.w3.org/2000/svg" width="3.331"
													height="14.434" viewBox="0 0 3.331 14.434" className="iconClose">
													<g data-v-bd82969a="" id="menu" transform="translate(0 -61)">
														<g data-v-bd82969a="" id="Group_13136" data-name="Group 13136"
															transform="translate(0 61)">
															<g data-v-bd82969a="" id="Group_13135"
																data-name="Group 13135">
																<path data-v-bd82969a="" id="Path_29631"
																	data-name="Path 29631"
																	d="M1.665,61a1.665,1.665,0,1,0,1.665,1.665A1.667,1.667,0,0,0,1.665,61Z"
																	transform="translate(0 -61)" fill="#fff" />
															</g>
														</g>
														<g data-v-bd82969a="" id="Group_13138" data-name="Group 13138"
															transform="translate(0 66.551)">
															<g data-v-bd82969a="" id="Group_13137"
																data-name="Group 13137">
																<path data-v-bd82969a="" id="Path_29632"
																	data-name="Path 29632"
																	d="M1.665,211a1.665,1.665,0,1,0,1.665,1.665A1.667,1.667,0,0,0,1.665,211Z"
																	transform="translate(0 -211)" fill="#fff" />
															</g>
														</g>
														<g data-v-bd82969a="" id="Group_13140" data-name="Group 13140"
															transform="translate(0 72.103)">
															<g data-v-bd82969a="" id="Group_13139"
																data-name="Group 13139">
																<path data-v-bd82969a="" id="Path_29633"
																	data-name="Path 29633"
																	d="M1.665,361a1.665,1.665,0,1,0,1.665,1.665A1.667,1.667,0,0,0,1.665,361Z"
																	transform="translate(0 -361)" fill="#fff" />
															</g>
														</g>
													</g>
												</svg>
												<span data-v-bd82969a="" className="totalCount text-uppercase" style={{ display: total_bet_open > 0 ? 'initial' : 'none' }}>{total_bet_open}</span>
											</div>
										</li>}
										{isCurrentRoute(constants.ROUTE.TRADING) && isMobile() && <li data-v-bd82969a="">
											<div data-v-bd82969a="" className={setClassNameCondition(is_show_transaction, 'notification-dropdown--active', '', 'notification-dropdown mobile notification-transaction')}>
												<div className="notification-dropdown-button mobile" onClick={this.onToggle('is_show_transaction')}>
													<span className="notification-dropdown-button-icon">
														<svg data-v-bd82969a="" xmlns="http://www.w3.org/2000/svg" width="3.331" height="14.434" viewBox="0 0 3.331 14.434"
															className="iconClose">
															<g data-v-bd82969a="" id="menu" transform="translate(0 -61)"><g data-v-bd82969a=""
																id="Group_13136"
																data-name="Group 13136"
																transform="translate(0 61)"><g
																	data-v-bd82969a="" id="Group_13135" data-name="Group 13135"><path data-v-bd82969a="" id="Path_29631"
																		data-name="Path 29631"
																		d="M1.665,61a1.665,1.665,0,1,0,1.665,1.665A1.667,1.667,0,0,0,1.665,61Z"
																		transform="translate(0 -61)"
																		fill="#fff" /></g></g><g data-v-bd82969a=""
																			id="Group_13138"
																			data-name="Group 13138"
																			transform="translate(0 66.551)"><g
																				data-v-bd82969a="" id="Group_13137" data-name="Group 13137"><path data-v-bd82969a="" id="Path_29632"
																					data-name="Path 29632"
																					d="M1.665,211a1.665,1.665,0,1,0,1.665,1.665A1.667,1.667,0,0,0,1.665,211Z"
																					transform="translate(0 -211)" fill="#fff" /></g></g><g
																						data-v-bd82969a="" id="Group_13140" data-name="Group 13140" transform="translate(0 72.103)"><g data-v-bd82969a=""
																							id="Group_13139"
																							data-name="Group 13139"><path
																			data-v-bd82969a="" id="Path_29633" data-name="Path 29633"
																			d="M1.665,361a1.665,1.665,0,1,0,1.665,1.665A1.667,1.667,0,0,0,1.665,361Z" transform="translate(0 -361)"
																			fill="#fff" /></g></g></g>
														</svg>
													</span>
													<span className="notification-dropdown-button-number" style={{ display: total_bet_open > 0 ? 'initial' : 'none' }}>{total_bet_open}</span>
												</div>
												{is_show_transaction && <>
													<div className="notification-dropdown-body">
														<div className="notification-dropdown-header">
															<h3 className="notification-dropdown-title">{t('common.recent_transaction')}</h3>
															<span className="notification-dropdown-close pe pe-7s-close" onClick={this.onToggle('is_show_transaction')} />
														</div>
														<div className="notification-dropdown-list">
															<div data-v-5b2de64c="" className="flex-100 px-1">
																<TransactionBox />
															</div>
														</div>
													</div>
													<div className="notification-dropdown-mask" onClick={this.onToggle('is_show_transaction')} />
												</>}
											</div>
										</li>}
									</ul>

								)
								:
								<div data-v-bd82969a="" className={'d-flex align-items-center pr-1 cg-7 ' + (isDesktop() ? '' : 'groupAuthentication')}>
									<LanguageSelector />
									<button data-v-bd82969a="" href="#" onClick={navigateTo('/login').bind(this)} className="button wbtn btn-large btn-radius started bgSecondaryBtn3">
										<span data-v-bd82969a="" className={'font-14 text-capitalize ' + (isDesktop() ? '' : 'font-14m')}>{t('common.login')}</span>
									</button>
									<button data-v-bd82969a="" href="#" onClick={navigateTo('/register').bind(this)} className="button wbtn btn-large btn-radius started bgSecondaryBtn3">
										<span data-v-bd82969a="" className={'font-14 text-capitalize ' + (isDesktop() ? '' : 'font-14m')}>{t('common.register')}</span>
									</button>
								</div>
							}
						</div>
					</header>
				</div>
			</div>
		</div>
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer,
		transactionReducer: state.transactionReducer,
		notificationReducer: state.notificationReducer,
	};
}

export default connect(mapStateToProps, {
	fetchingUser,
	fetchingUserSuccess,
	updateAppConfigAction,
	fetchingTransactionAction,
	fetchingNotification,
	logoutUser
})(withTranslation()(withRouter(Header)));
