import { createReducer } from 'redux-act';
import { updateChartAction } from '../actions';

export default createReducer({
	[updateChartAction]: (state, payload) => {
		return {...state, ...payload};
	}
}, {
	x: null,
	open: null,
	high: null,
	low: null,
	close: null,
	vol: null,
	color: "#FA2843",
	second: 0,
	psychological_indicators: 50
});
