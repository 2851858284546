import '../../assets/css/profile.css';

import { Component } from 'react';
import { connect } from "react-redux";
import { fetchingUser, fetchingUserSuccess } from "../../redux/actions";
import { withRouter } from "react-router-dom";
import QRCode from 'qrcode';
import api from "../../lib/api";

import {
	copyText,
	formatNumber, getUrl, setClassNameCondition,
	showNotification
} from "../../lib/helper";
import { withTranslation } from 'react-i18next';

const PAYMENT_METHODS = {
	bank: {
		name: 'bank',
		rate: 'vnd_per_usd',
		currency: 'VNĐ'
	},
	usdt: {
		name: 'usdt',
		rate: 'usdt_per_usd',
		currency: 'USDT'
	},
	cny: {
		name: 'cny',
		rate: 'cny_per_usd',
		currency: 'CNY'
	}
};

class Deposit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props.memberReducer,
			deposit_value: 0,
			deposit_value_usd: 0,
			deposit_value_vnd: 0,
			cny_to_vnd_rate: 3.432,
			is_show_deposit_success: false,
			payment_method: PAYMENT_METHODS.bank.name
		};
	}

	onChangeDepositValue = (e) => {
		let { payment_method } = this.state;
		let value = parseFloat(e.target.value.toString().replace(/,/g, ''));
		let rate = this.props.appReducer[PAYMENT_METHODS[payment_method].rate];
		this.setState({ deposit_value_usd: Math.floor(value / rate), deposit_value: value });
	}

	onChangeUSD = (e) => {
		let { payment_method } = this.state;
		let rate = this.props.appReducer[PAYMENT_METHODS[payment_method].rate];
		let value = parseFloat(e.target.value.toString().replace(/,/g, ''));
		this.setState({ deposit_value_usd: value, deposit_value: value * rate });
	}

	onRecharge = () => {
		let { deposit_value_usd, payment_method } = this.state;
		let { usdt_address } = this.props.appReducer;
		api.postRecharge({ amount: deposit_value_usd, payment_method })
			.then(({ data, message, success }) => {
				showNotification({ message, type: success ? 'success' : 'danger' });
				success && this.setState({ is_show_deposit_success: true, deposit_transaction: data.code });
				QRCode.toString(usdt_address).then(qrCode => {
					this.setState({ qr_code: qrCode });
				});
			});
	}

	onChangePaymentMethod = (method) => () => {
		let { deposit_value_usd } = this.state;
		let rate = this.props.appReducer[PAYMENT_METHODS[method].rate];
		console.log([method]);
		this.setState({ payment_method: method, deposit_value: deposit_value_usd * rate })
	}

	onCopyAddress = () => {
		let { usdt_address } = this.props.appReducer;
		copyText(usdt_address);
	}

	componentDidMount() {
		if (!this.props.memberReducer?.login_token) {
			return this.props.history.push('/login');
		}
	}

	render() {
		let { deposit_value, deposit_value_usd, is_show_deposit_success, deposit_transaction, payment_method, qr_code, cny_to_vnd_rate } = this.state;
		let { minimum_deposit, bank_data, usdt_address } = this.props.appReducer;
		bank_data = bank_data.replace(/\r?\n/g, '<br/>');
		const { t } = this.props;
		return (
			<div data-v-b0e45e8c="" id="mainContent">
				<div data-v-b0e45e8c="" className="boxContent">
					<div data-v-9792f17c="" data-v-b0e45e8c="">
						<div data-v-9792f17c="" className="profileContent">
							<div data-v-9792f17c="" className="container">
								<div data-v-9792f17c="" className="panel panel--security">
									<div data-v-9792f17c="" className="panel-header">
										<h3 data-v-9792f17c="" className="panel-header-title">{t('deposit.title')}</h3>
									</div>
									<div data-v-9792f17c="" className="panel-body">
										{!is_show_deposit_success ? <>
											<div data-v-9792f17c="" className="mb-5">
												<div data-v-9792f17c="" className="form-group">
													<span data-v-9792f17c="" className="labelInput">{t('deposit.choose_the_deposit_method')}</span>
													<ul className="pl-3 list-unstyled payment-method row">
														<li onClick={this.onChangePaymentMethod(PAYMENT_METHODS.bank.name)} className={setClassNameCondition(payment_method === PAYMENT_METHODS.bank.name, 'active', '', 'item col-md-2 col-sm-5 col-12')}>{t('deposit.deposit_vnd')}</li>
														<li onClick={this.onChangePaymentMethod(PAYMENT_METHODS.usdt.name)} className={setClassNameCondition(payment_method === PAYMENT_METHODS.usdt.name, 'active', '', 'item col-md-2 col-sm-5 col-12')}>{t('deposit.deposit_usdt')}</li>
														<li onClick={this.onChangePaymentMethod(PAYMENT_METHODS.cny.name)} className={setClassNameCondition(payment_method === PAYMENT_METHODS.cny.name, 'active', '', 'item col-md-2 col-sm-5 col-12')}>{t('deposit.deposit_cny')}</li>
													</ul>
												</div>
											</div>
											<div data-v-9792f17c="" className="row mb-5">
												<div data-v-9792f17c="" className="col-md-4 col-xs-12">
													<div data-v-9792f17c="" className="form-group">
														<span data-v-9792f17c="" className="labelInput">{t('deposit.amount_you_want_to_deposit')} ({PAYMENT_METHODS[payment_method].currency})</span>
														<input data-v-9792f17c=""
															placeholder={t('deposit.amount_you_want_to_deposit')}
															value={deposit_value || 0}
															onChange={this.onChangeDepositValue}
															type="text" className="form-control mb-2 winput input-deposit" />
													</div>
												</div>
												<div data-v-9792f17c="" className="col-md-4 col-xs-12">
													<div data-v-9792f17c="" className="form-group deposit-usd">
														<span data-v-9792f17c="" className="labelInput">{t('deposit.the_amount_you_want_to_receive')} (USD)</span>
														<span className='usd-symbol'>$</span>
														<input
															placeholder={t('deposit.the_amount_you_want_to_receive')}
															data-v-9792f17c=""
															value={formatNumber(deposit_value_usd)}
															onChange={this.onChangeUSD}
															type="text"
															className="form-control mb-2 winput input-deposit" />
													</div>
												</div>
												<div data-v-9792f17c="" className="col-md-4 col-xs-12">
													<button data-v-9792f17c="" style={{ marginTop: '25px', height: '43px', width: '100%' }}
														type="button"
														onClick={this.onRecharge}
														className="button wbtn btn-radius btn-large btn-border color-white btn-secondary">{t('deposit.loading_request')}
													</button>
												</div>
											</div>
											<div data-v-9792f17c="" className="row">
												<div data-v-9792f17c="" className="col-12">
													<p data-v-110c803e="" className="noticeDeposit">
														<span data-v-110c803e="">{t('deposit.minimum_received_value_is')}: ${formatNumber(minimum_deposit)} ({formatNumber(minimum_deposit * this.props.appReducer[PAYMENT_METHODS[payment_method].rate])} {PAYMENT_METHODS[payment_method].currency})</span>
														<br />
														<span data-v-110c803e="">( {t('deposit.current_exchange_rate')}: $1 = {formatNumber(this.props.appReducer[PAYMENT_METHODS[payment_method].rate])} {PAYMENT_METHODS[payment_method].currency} )</span>
													</p>
												</div>
											</div>
										</>
											: <div className='row mb-5'>
												{payment_method === PAYMENT_METHODS.bank.name || payment_method === PAYMENT_METHODS.cny.name ? <div data-v-9792f17c="" className="col-12">
													<div data-v-110c803e="" className="noticeDeposit" style={{ color: '#FA2843', marginBottom: '20px', fontSize: '23px', fontWeight: 'bolder', textTransform: 'capitalize' }}>{t('deposit.request_sent_successfully')}</div>
													<div data-v-110c803e="" className="noticeDeposit deposit-change-font"><span>{t('deposit.please_transfer_the_correct_amount', { amount: payment_method === PAYMENT_METHODS.cny.name ? formatNumber(deposit_value * cny_to_vnd_rate)+' VNĐ' : formatNumber(deposit_value) + ' VNĐ' })}</span></div>
													<div data-v-110c803e="" className="noticeDeposit deposit highlight-text" dangerouslySetInnerHTML={{ __html: bank_data }} />
													<div data-v-110c803e="" className="noticeDeposit deposit deposit-change-font" style={{ margin: '25px 0px' }}>
														<span>{t('deposit.transfer_content')}:</span>
														<b className='highlight-text' style={{ marginLeft: '4px' }}>{deposit_transaction}</b>
														<span className='btn-copy' onClick={() => copyText(deposit_transaction)}>Copy</span>
													</div>
													<div data-v-110c803e="" className="noticeDeposit deposit deposit-change-font">
														<span style={{ color: 'red' }}>{t('deposit.notice')}</span><br />
														<span style={{ color: 'red' }}>( {t('deposit.please_call_hotline')} )</span>
													</div>
												</div> :
													<div data-v-9792f17c="" className="col-12">
														<div data-v-110c803e="" className="noticeDeposit" style={{ color: '#FA2843', marginBottom: '7px', fontSize: '18px', fontWeight: 'bolder' }}>{t('deposit.request_sent_successfully')}</div>
														<div data-v-110c803e="" className="noticeDeposit"><span>{t('deposit.please_transfer_the_correct_amount_usdt', { amount: formatNumber(deposit_value) + ' USDT' })}</span></div>
														<div data-v-110c803e="" className="noticeDeposit deposit">
															<span>{t('deposit.usdt_address')}:</span>
															<div className='copy-usdt'>
																<input type="text" disabled={true} className="form-control mb-2 winput input-deposit" value={usdt_address} />
																<span onClick={this.onCopyAddress} className='pe pe-7s-copy-file' />
															</div>
														</div>
														<div data-v-110c803e="" >
															<div style={{ maxHeight: '200px', maxWidth: '200px' }} dangerouslySetInnerHTML={{ __html: qr_code }} />
														</div>
													</div>}
											</div>}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default connect(mapStateToProps, {
	fetchingUser,
	fetchingUserSuccess
})(withTranslation()(withRouter(Deposit)));

