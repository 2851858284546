import { Component } from 'react';
import { connect } from "react-redux";
import { logoutUser, fetchingUserSuccess, updateAppConfigAction, fetchingNotification } from "../redux/actions";
import { withRouter } from 'react-router-dom';
import {
	isCurrentRoute,
	navigateTo,
	setClassNameCondition,
	showNotification,
	isDesktop,
	preventDefault
} from "../lib/helper";
import constants from "../lib/constants";
import api from "../lib/api";
import { withTranslation } from 'react-i18next';
import Notification from "../lib/custom/Notification";

class LeftSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			is_show_setting: false,

			enable_sound: 1,
			is_show_balance: 1,
			is_show_notification: false
		};
	}

	componentDidMount() {
		let { enable_sound, is_show_balance } = this.props.memberReducer || {};
		this.setState({ enable_sound, is_show_balance });
	}

	onLogout = (e) => {
		e.preventDefault();
		this.props.logoutUser();
		this.props.history.push('/');
	}

	onCloseMenu = (e) => {
		e.preventDefault();
		this.props.updateAppConfigAction({ is_show_menu: false });
	}

	onShowSetting = () => {
		this.setState({ is_show_setting: !this.state.is_show_setting });
	}

	onClickChangeSetting = (type) => () => {
		let value = this.state[type] === 1 ? 0 : 1;
		this.setState({ [type]: value }, () => {
			api.putChangeProfile({ [type]: value })
				.then(({ data, success, message }) => {
					if (success) {
						return this.props.fetchingUserSuccess(data);
					}
					showNotification({ message, type: success ? 'success' : 'danger' });
				});
		});
	}

	onNavigate = (to) => (e) => {
		this.onCloseMenu(e);
		navigateTo(to).bind(this)(e);
	}

	onMaintain = (e) => {
		e && e.preventDefault();
		showNotification({ message: 'Tính năng này hiện đang nâng cấp, vui lòng thử lại sau', type: 'warning' });
	}

	onChangeState = (type) => (e) => {
		e && e.preventDefault();
		this.setState({ [type]: !this.state[type] });
	}

	onReadAllNotification = () => {
		api.putMarkReadAllNotification().then(() => this.props.fetchingNotification());
	}

	onViewAllNotification = () => {
		this.setState({ is_show_notification: false });
		navigateTo('notification').bind(this)();
	}

	render() {
		let { window_width, is_show_menu, phone, cskh_link } = this.props.appReducer;
		let { display_name, avatar, is_verify, verified_point } = this.props.memberReducer;
		let { is_show_setting, enable_sound, is_show_balance, is_show_notification } = this.state;
		let { un_read_count, notification } = this.props.notificationReducer;
		const { t } = this.props;
		return (
			isDesktop()
				?
				<div data-v-c6d93ce8="" data-v-b0e45e8c="" id="leftSidebar" className="sideBar">
					<div data-v-c6d93ce8="" className="contentSidebar">
						<div data-v-c6d93ce8="" className="topSidebar">
							<ul data-v-c6d93ce8="" className="nav flex-column">
								<li data-v-c6d93ce8="" className="nav-item">
									<a data-v-c6d93ce8="" href="#"
										onClick={navigateTo('trading').bind(this)}
										className={'nav-link ' + (isCurrentRoute(constants.ROUTE.TRADING) ? 'router-link-exact-active router-link-active' : '')}>
										<span data-v-c6d93ce8="">
											<svg data-v-c6d93ce8=""
												xmlns="http://www.w3.org/2000/svg"
												width="19.776" height="20.801"
												viewBox="0 0 19.776 20.801"
												className="non-active"><g data-v-c6d93ce8=""
													id="bracket-arrow"
													transform="translate(0 21.189) rotate(-90)"><g
														data-v-c6d93ce8="" id="Path_30752" data-name="Path 30752"
														transform="translate(2.447 0)" fill="none" strokeLinecap="round"
														strokeLinejoin="round"><path data-v-c6d93ce8=""
															d="M9.5,13.385l3.226-3.5L9.5,6.391V1.5l7.743,8.388L9.5,18.276Z"
															stroke="none" /> <path
															data-v-c6d93ce8=""
															d="M 9.499995231628418 18.2755241394043 L 17.24255561828613 9.887763977050781 L 9.499995231628418 1.500003933906555 L 9.499995231628418 6.390714168548584 L 12.72606468200684 9.887763977050781 L 9.499995231628418 13.38481426239014 L 9.499995231628418 18.2755241394043 M 9.499653816223145 19.77558326721191 C 9.316328048706055 19.77558326721191 9.131294250488281 19.74194717407227 8.953784942626953 19.67254447937012 C 8.378564834594727 19.44763374328613 7.99999475479126 18.89314460754395 7.99999475479126 18.2755241394043 L 7.99999475479126 13.38481426239014 C 7.99999475479126 13.00788402557373 8.141904830932617 12.64477443695068 8.397475242614746 12.3677339553833 L 10.68527698516846 9.887763977050781 L 8.397475242614746 7.407793998718262 C 8.141904830932617 7.130753993988037 7.99999475479126 6.767643928527832 7.99999475479126 6.390714168548584 L 7.99999475479126 1.500003933906555 C 7.99999475479126 0.8823840022087097 8.378564834594727 0.3278939723968506 8.953784942626953 0.1029839739203453 C 9.131258964538574 0.03359496966004372 9.316363334655762 -5.543701263377443e-05 9.499653816223145 -5.543701263377443e-05 C 9.910433769226074 -5.543701263377443e-05 10.31252574920654 0.1687753796577454 10.60219478607178 0.4825839698314667 L 18.34475517272949 8.870344161987305 C 18.87515449523926 9.444933891296387 18.87515449523926 10.33059406280518 18.34475517272949 10.90518379211426 L 10.60219478607178 19.29294395446777 C 10.31255149841309 19.60672569274902 9.910395622253418 19.77558326721191 9.499653816223145 19.77558326721191 Z"
															stroke="none" fill="#fff" /></g> <g data-v-c6d93ce8=""
																id="Path_30753"
																data-name="Path 30753"
																transform="translate(1.389 1.685)"
																fill="none"
																strokeLinecap="round"
																strokeLinejoin="round"><path
															data-v-c6d93ce8=""
															d="M6.687,14.905.5,8.2,6.687,1.5V5.408L4.109,8.2,6.687,11Z"
															stroke="none" /> <path data-v-c6d93ce8=""
																d="M 6.686956405639648 14.90506172180176 L 6.686956405639648 10.99697208404541 L 4.10905647277832 8.202531814575195 L 6.686956405639648 5.408092021942139 L 6.686956405639648 1.500001788139343 L 0.4999963045120239 8.202531814575195 L 6.686956405639648 14.90506172180176 M 6.687297821044922 16.40512084960938 C 6.276517391204834 16.40512084960938 5.874425888061523 16.23628997802734 5.584756374359131 15.92248153686523 L -0.6022037267684937 9.219951629638672 C -1.132603645324707 8.64536190032959 -1.132603645324707 7.759701728820801 -0.6022037267684937 7.185111999511719 L 5.584756374359131 0.4825818240642548 C 5.87440013885498 0.1688009202480316 6.276555061340332 -5.759948908234946e-05 6.687297821044922 -5.759948908234946e-05 C 6.870623588562012 -5.759948908234946e-05 7.055656909942627 0.03357908129692078 7.233166217803955 0.1029818132519722 C 7.808386325836182 0.3278918266296387 8.186956405639648 0.882381796836853 8.186956405639648 1.500001788139343 L 8.186956405639648 5.408092021942139 C 8.186956405639648 5.785021781921387 8.045045852661133 6.148131847381592 7.78947639465332 6.425171852111816 L 6.149843215942383 8.202531814575195 L 7.78947639465332 9.979891777038574 C 8.045045852661133 10.25693225860596 8.186956405639648 10.620041847229 8.186956405639648 10.99697208404541 L 8.186956405639648 14.90506172180176 C 8.186956405639648 15.52268218994141 7.808386325836182 16.07717132568359 7.233166217803955 16.30208206176758 C 7.055692195892334 16.3714714050293 6.870587825775146 16.40512084960938 6.687297821044922 16.40512084960938 Z"
																stroke="none"
																fill="#fff" /></g></g></svg>
											<svg data-v-c6d93ce8=""
												xmlns="http://www.w3.org/2000/svg"
												width="19.776"
												height="20.801" viewBox="0 0 19.776 20.801"
												className="active"><g
													data-v-c6d93ce8="" id="bracket-arrow"
													transform="translate(0 21.189) rotate(-90)"><g data-v-c6d93ce8=""
														id="Path_30752"
														data-name="Path 30752"
														transform="translate(2.447 0)"
														fill="none"
														strokeLinecap="round"
														strokeLinejoin="round"><path
															data-v-c6d93ce8=""
															d="M9.5,13.385l3.226-3.5L9.5,6.391V1.5l7.743,8.388L9.5,18.276Z"
															stroke="none" /> <path data-v-c6d93ce8=""
																d="M 9.499995231628418 18.2755241394043 L 17.24255561828613 9.887763977050781 L 9.499995231628418 1.500003933906555 L 9.499995231628418 6.390714168548584 L 12.72606468200684 9.887763977050781 L 9.499995231628418 13.38481426239014 L 9.499995231628418 18.2755241394043 M 9.499653816223145 19.77558326721191 C 9.316328048706055 19.77558326721191 9.131294250488281 19.74194717407227 8.953784942626953 19.67254447937012 C 8.378564834594727 19.44763374328613 7.99999475479126 18.89314460754395 7.99999475479126 18.2755241394043 L 7.99999475479126 13.38481426239014 C 7.99999475479126 13.00788402557373 8.141904830932617 12.64477443695068 8.397475242614746 12.3677339553833 L 10.68527698516846 9.887763977050781 L 8.397475242614746 7.407793998718262 C 8.141904830932617 7.130753993988037 7.99999475479126 6.767643928527832 7.99999475479126 6.390714168548584 L 7.99999475479126 1.500003933906555 C 7.99999475479126 0.8823840022087097 8.378564834594727 0.3278939723968506 8.953784942626953 0.1029839739203453 C 9.131258964538574 0.03359496966004372 9.316363334655762 -5.543701263377443e-05 9.499653816223145 -5.543701263377443e-05 C 9.910433769226074 -5.543701263377443e-05 10.31252574920654 0.1687753796577454 10.60219478607178 0.4825839698314667 L 18.34475517272949 8.870344161987305 C 18.87515449523926 9.444933891296387 18.87515449523926 10.33059406280518 18.34475517272949 10.90518379211426 L 10.60219478607178 19.29294395446777 C 10.31255149841309 19.60672569274902 9.910395622253418 19.77558326721191 9.499653816223145 19.77558326721191 Z"
																stroke="none" fill="#2076fe" /></g> <g
																	data-v-c6d93ce8="" id="Path_30753" data-name="Path 30753"
																	transform="translate(1.389 1.685)" fill="none"
																	strokeLinecap="round" strokeLinejoin="round"><path
															data-v-c6d93ce8=""
															d="M6.687,14.905.5,8.2,6.687,1.5V5.408L4.109,8.2,6.687,11Z"
															stroke="none" /> <path data-v-c6d93ce8=""
																d="M 6.686956405639648 14.90506172180176 L 6.686956405639648 10.99697208404541 L 4.10905647277832 8.202531814575195 L 6.686956405639648 5.408092021942139 L 6.686956405639648 1.500001788139343 L 0.4999963045120239 8.202531814575195 L 6.686956405639648 14.90506172180176 M 6.687297821044922 16.40512084960938 C 6.276517391204834 16.40512084960938 5.874425888061523 16.23628997802734 5.584756374359131 15.92248153686523 L -0.6022037267684937 9.219951629638672 C -1.132603645324707 8.64536190032959 -1.132603645324707 7.759701728820801 -0.6022037267684937 7.185111999511719 L 5.584756374359131 0.4825818240642548 C 5.87440013885498 0.1688009202480316 6.276555061340332 -5.759948908234946e-05 6.687297821044922 -5.759948908234946e-05 C 6.870623588562012 -5.759948908234946e-05 7.055656909942627 0.03357908129692078 7.233166217803955 0.1029818132519722 C 7.808386325836182 0.3278918266296387 8.186956405639648 0.882381796836853 8.186956405639648 1.500001788139343 L 8.186956405639648 5.408092021942139 C 8.186956405639648 5.785021781921387 8.045045852661133 6.148131847381592 7.78947639465332 6.425171852111816 L 6.149843215942383 8.202531814575195 L 7.78947639465332 9.979891777038574 C 8.045045852661133 10.25693225860596 8.186956405639648 10.620041847229 8.186956405639648 10.99697208404541 L 8.186956405639648 14.90506172180176 C 8.186956405639648 15.52268218994141 7.808386325836182 16.07717132568359 7.233166217803955 16.30208206176758 C 7.055692195892334 16.3714714050293 6.870587825775146 16.40512084960938 6.687297821044922 16.40512084960938 Z"
																stroke="none" fill="#2076fe" /></g></g>
											</svg>
										</span>
										<span data-v-c6d93ce8=""
											className="text-center name color-white-50">{t('common.transaction')}</span></a>
								</li>
								<li data-v-c6d93ce8="" className="nav-item">
									<a data-v-c6d93ce8="" onClick={navigateTo('challenge').bind(this)} href="#"
										className={setClassNameCondition(isCurrentRoute(constants.ROUTE.CHALLENGE), 'router-link-exact-active router-link-active', '', 'nav-link')}>
										<span data-v-c6d93ce8="" className="position-relative mb-2">
											<svg data-v-c6d93ce8="" xmlns="http://www.w3.org/2000/svg" width="18.981" height="20.266" viewBox="0 0 18.981 20.266" className="non-active">
												<g data-v-c6d93ce8="" id="trophy"
													transform="translate(-1.5 -0.5)"><path
														data-v-c6d93ce8="" id="Path_31718" data-name="Path 31718"
														d="M5.211,6.78H4.569A2.569,2.569,0,0,1,2,4.211V1H5.211" fill="none" stroke="#fff"
														strokeMiterlimit="10" strokeWidth="1" /> <path data-v-c6d93ce8="" id="Path_31719"
															data-name="Path 31719"
															d="M25,6.78h.642a2.569,2.569,0,0,0,2.569-2.569V1H25"
															transform="translate(-8.23)"
															fill="none" stroke="#fff"
															strokeMiterlimit="10"
															strokeWidth="1" /> <line
														data-v-c6d93ce8="" id="Line_1501" data-name="Line 1501" y1="4"
														transform="translate(11 12.571)" fill="none" stroke="#fff" strokeMiterlimit="10"
														strokeWidth="1" /> <path data-v-c6d93ce8="" id="Path_31720" data-name="Path 31720"
															d="M12.78,13.2h0A5.78,5.78,0,0,1,7,7.422V1H18.559V7.422a5.78,5.78,0,0,1-5.78,5.78Z"
															transform="translate(-1.789)" fill="none" stroke="#fff"
															strokeLinecap="square" strokeMiterlimit="10"
															strokeWidth="1" /> <path data-v-c6d93ce8=""
																id="Path_31721"
																data-name="Path 31721"
																d="M17.991,29.211H9A3.211,3.211,0,0,1,12.211,26H14.78A3.211,3.211,0,0,1,17.991,29.211Z"
																transform="translate(-2.505 -8.945)"
																fill="none" stroke="#fff"
																strokeLinecap="square"
																strokeMiterlimit="10"
																strokeWidth="1" /></g>
											</svg>
											<svg data-v-c6d93ce8="" xmlns="http://www.w3.org/2000/svg" width="18.981" height="20.266" viewBox="0 0 18.981 20.266" className="active"><g data-v-c6d93ce8="" id="trophy"
												transform="translate(-1.5 -0.5)"><path
													data-v-c6d93ce8="" id="Path_31718" data-name="Path 31718"
													d="M5.211,6.78H4.569A2.569,2.569,0,0,1,2,4.211V1H5.211" fill="none" stroke="#1790ee"
													strokeMiterlimit="10" strokeWidth="1" /> <path data-v-c6d93ce8="" id="Path_31719"
														data-name="Path 31719"
														d="M25,6.78h.642a2.569,2.569,0,0,0,2.569-2.569V1H25"
														transform="translate(-8.23)"
														fill="none" stroke="#1790ee"
														strokeMiterlimit="10"
														strokeWidth="1" /> <line
													data-v-c6d93ce8="" id="Line_1501" data-name="Line 1501" y1="4"
													transform="translate(11 12.571)" fill="none" stroke="#1790ee" strokeMiterlimit="10"
													strokeWidth="1" /> <path data-v-c6d93ce8="" id="Path_31720" data-name="Path 31720"
														d="M12.78,13.2h0A5.78,5.78,0,0,1,7,7.422V1H18.559V7.422a5.78,5.78,0,0,1-5.78,5.78Z"
														transform="translate(-1.789)" fill="none"
														stroke="#1790ee" strokeLinecap="square"
														strokeMiterlimit="10" strokeWidth="1" /> <path
													data-v-c6d93ce8="" id="Path_31721" data-name="Path 31721"
													d="M17.991,29.211H9A3.211,3.211,0,0,1,12.211,26H14.78A3.211,3.211,0,0,1,17.991,29.211Z"
													transform="translate(-2.505 -8.945)" fill="none" stroke="#1790ee"
													strokeLinecap="square" strokeMiterlimit="10" strokeWidth="1" /></g>
											</svg>
											<span data-v-c6d93ce8="" className="iconLive pc">
												<span data-v-c6d93ce8="" className="live">
													<svg data-v-c6d93ce8="" xmlns="http://www.w3.org/2000/svg" width="24.441" height="11.052" viewBox="0 0 24.441 11.052"><g data-v-c6d93ce8="" id="_1246264" data-name="1246264"
														transform="translate(8.061)"><path data-v-c6d93ce8=""
															id="Path_34924"
															data-name="Path 34924"
															d="M.07,43.94c.02.667.767,1.2,1.682,1.2H22.823c.931,0,1.686-.55,1.686-1.228V35.449Zm0,0"
															transform="translate(-8.128 -34.089)"
															fill="#ff3051" /> <path
															data-v-c6d93ce8="" id="Path_34925" data-name="Path 34925"
															d="M24.441,1.228C24.441.55,23.686,0,22.756,0H1.686C.755,0,0,.55,0,1.228v8.6c0,.009,0,.018,0,.027L24.441,1.36Zm0,0"
															transform="translate(-8.061)" fill="#ff4764" /> <g data-v-c6d93ce8=""
																id="Group_21085"
																data-name="Group 21085"
																transform="translate(-1.217 2.985)"><path
																data-v-c6d93ce8="" id="Path_34926" data-name="Path 34926"
																d="M59.16,76.383H56.7V72.274a.362.362,0,0,0-.7,0v4.383a.32.32,0,0,0,.351.274H59.16a.282.282,0,1,0,0-.548Zm0,0"
																transform="translate(-56 -72)" fill="#f1f2f2" stroke="#fff" strokeWidth="0.2" /> <path
																data-v-c6d93ce8="" id="Path_34927" data-name="Path 34927"
																d="M331.16,72.548a.282.282,0,1,0,0-.548h-2.809a.32.32,0,0,0-.351.274v4.383a.32.32,0,0,0,.351.274h2.809a.282.282,0,1,0,0-.548H328.7V74.739h1.755a.282.282,0,1,0,0-.548H328.7V72.548Zm0,0"
																transform="translate(-316.063 -72)" fill="#f1f2f2" stroke="#fff"
																strokeWidth="0.2" /> <path data-v-c6d93ce8="" id="Path_34928"
																	data-name="Path 34928"
																	d="M160.351,72a.32.32,0,0,0-.351.274v4.383a.362.362,0,0,0,.7,0V72.274A.32.32,0,0,0,160.351,72Zm0,0"
																	transform="translate(-155.436 -72)" fill="#f1f2f2"
																	stroke="#fff" strokeWidth="0.2" /> <path
																data-v-c6d93ce8="" id="Path_34929" data-name="Path 34929"
																d="M204.326,72.007a.443.443,0,0,0-.269.007.315.315,0,0,0-.183.153L202.127,75.8l-1.45-3.622a.378.378,0,0,0-.441-.18c-.185.045-.288.2-.23.344l1.755,4.383a.35.35,0,0,0,.326.192h.01a.359.359,0,0,0,.329-.178l2.107-4.383a.219.219,0,0,0-.009-.21A.331.331,0,0,0,204.326,72.007Zm0,0"
																transform="translate(-193.67 -71.99)" fill="#f1f2f2" stroke="#fff"
																strokeWidth="0.2" /></g> <circle data-v-c6d93ce8="" id="Ellipse_2017"
																	data-name="Ellipse 2017" cx="1.5" cy="1.5"
																	r="1.5" transform="translate(-6.619 3.969)"
																	fill="#fff" /></g></svg>
												</span>
											</span>
										</span>
										<span data-v-c6d93ce8="" className="text-center name color-white-50">{t('common.event')}</span>
									</a>
								</li>
								{/* <li data-v-c6d93ce8="" className="nav-item">
								<a data-v-c6d93ce8="" href="#" onClick={this.onMaintain} className="nav-link">
									<span data-v-c6d93ce8="">
										<svg data-v-c6d93ce8="" xmlns="http://www.w3.org/2000/svg" width="26.569" height="26.569" viewBox="0 0 26.569 26.569"><g data-v-c6d93ce8="" id="ticket" transform="matrix(0.966, -0.259, 0.259, 0.966, 0, 5.615)"><path data-v-c6d93ce8="" id="Path_14066" data-name="Path 14066" d="M12.485,5.123,16.57,9.208,9.208,16.57,5.123,12.485Zm-2.7,12.6,7.962-7.939a.834.834,0,0,0,.231-.577.919.919,0,0,0-.231-.6L13.085,3.946a.812.812,0,0,0-.6-.231.736.736,0,0,0-.577.231L3.969,11.908a.783.783,0,0,0,0,1.154l4.662,4.662a.783.783,0,0,0,1.154,0ZM21.232,9.531,9.554,21.232a1.644,1.644,0,0,1-1.177.462,1.566,1.566,0,0,1-1.154-.462L5.585,19.616a2.452,2.452,0,0,0,0-3.508,2.4,2.4,0,0,0-1.754-.738,2.321,2.321,0,0,0-1.731.738L.485,14.47A1.528,1.528,0,0,1,0,13.316a1.6,1.6,0,0,1,.485-1.177L12.162.485A1.576,1.576,0,0,1,13.316,0a1.653,1.653,0,0,1,1.177.485l1.615,1.592a2.4,2.4,0,0,0-.738,1.754,2.4,2.4,0,0,0,.738,1.754,2.452,2.452,0,0,0,3.508,0L21.232,7.2a1.644,1.644,0,0,1,.462,1.177,1.566,1.566,0,0,1-.462,1.154Z" fill="#fff"/></g></svg>
									</span>
									<span data-v-c6d93ce8="" className="text-center name color-white-50">Rút Thăm May Mắn</span>
								</a>
							</li> */}

								<li data-v-c6d93ce8="" className="nav-item">
									<a data-v-c6d93ce8="" href="#" onClick={this.onMaintain} className="nav-link new">
										<span data-v-c6d93ce8="">
											<svg data-v-c6d93ce8="" xmlns="http://www.w3.org/2000/svg" width="21.597" height="28.337" viewBox="0 0 21.597 28.337"><g
												data-v-c6d93ce8="" id="Group_22170" data-name="Group 22170"
												transform="translate(-24.357 -313.104)"><g data-v-c6d93ce8="" id="gift-box"
													transform="translate(9.771 313.104)"><g
														data-v-c6d93ce8="" id="Group_22167" data-name="Group 22167"
														transform="translate(14.586 0)"><g data-v-c6d93ce8="" id="Group_22163"
															data-name="Group 22163"
															transform="translate(6.033 0)"><g data-v-c6d93ce8=""
																id="Group_22153"
																data-name="Group 22153"
																transform="translate(4.243)"><g
																	data-v-c6d93ce8="" id="Group_22152" data-name="Group 22152"><g data-v-c6d93ce8=""
																		id="Group_22151"
																		data-name="Group 22151"><path
																			data-v-c6d93ce8="" id="Path_67112" data-name="Path 67112"
																			d="M246.635,7.043a2.119,2.119,0,0,1-.728-.127,2.452,2.452,0,0,1-1.5-1.854,4.455,4.455,0,0,1,.206-2.459A4.455,4.455,0,0,1,246.041.586a2.452,2.452,0,0,1,2.337-.459c1.416.515,1.982,2.41,1.289,4.313a4.455,4.455,0,0,1-1.423,2.016A2.671,2.671,0,0,1,246.635,7.043Zm1.011-4.977a.674.674,0,0,0-.352.164,2.413,2.413,0,0,0-.735,1.08,2.413,2.413,0,0,0-.131,1.3c.055.245.149.352.186.366s.178-.008.378-.16a2.413,2.413,0,0,0,.735-1.08c.341-.938.091-1.612-.055-1.665a.077.077,0,0,0-.025,0Z"
																			transform="translate(-244.323 0)" fill="#ff993a" /></g></g></g> <g
																				data-v-c6d93ce8="" id="Group_22155" data-name="Group 22155"
																				transform="translate(4.243 1.164)"><g data-v-c6d93ce8="" id="Group_22154"
																					data-name="Group 22154"><path data-v-c6d93ce8=""
																						id="Path_67113"
																						data-name="Path 67113"
																						d="M249.666,29.306a4.455,4.455,0,0,1-1.423,2.016,2.672,2.672,0,0,1-1.609.586,2.119,2.119,0,0,1-.728-.127,2.452,2.452,0,0,1-1.495-1.854,4.084,4.084,0,0,1-.046-1.458,3.549,3.549,0,0,0,1.7.429q.169,0,.334-.016a1.907,1.907,0,0,0,.03.592c.055.245.149.352.186.365s.178-.008.378-.161a2.415,2.415,0,0,0,.734-1.08c.017-.047.032-.092.046-.137a3.57,3.57,0,0,0,1.79-2.432A4,4,0,0,1,249.666,29.306Z"
																						transform="translate(-244.322 -26.03)"
																						fill="#f97c15" /></g></g> <g
																							data-v-c6d93ce8="" id="Group_22158" data-name="Group 22158"
																							transform="translate(0 0.007)"><g data-v-c6d93ce8="" id="Group_22157"
																								data-name="Group 22157"><g data-v-c6d93ce8=""
																									id="Group_22156"
																									data-name="Group 22156"><path
																			data-v-c6d93ce8="" id="Path_67114" data-name="Path 67114"
																			d="M151.182,6.619A4.455,4.455,0,0,1,149.759,4.6c-.693-1.9-.126-3.8,1.289-4.313a2.452,2.452,0,0,1,2.337.459,4.455,4.455,0,0,1,1.423,2.016,4.455,4.455,0,0,1,.206,2.459,2.452,2.452,0,0,1-1.5,1.854,2.119,2.119,0,0,1-.728.127,2.671,2.671,0,0,1-1.609-.586Zm.573-4.388c-.145.053-.4.728-.055,1.665a2.413,2.413,0,0,0,.735,1.08c.2.152.34.174.378.16s.131-.12.186-.366a2.413,2.413,0,0,0-.131-1.3,2.413,2.413,0,0,0-.735-1.08.674.674,0,0,0-.352-.164.078.078,0,0,0-.025,0Z"
																			transform="translate(-149.464 -0.163)" fill="#ffaf40" /></g></g></g> <g
																				data-v-c6d93ce8="" id="Group_22160" data-name="Group 22160"
																				transform="translate(0 0.007)"><g data-v-c6d93ce8="" id="Group_22159"
																					data-name="Group 22159"><path data-v-c6d93ce8=""
																						id="Path_67115"
																						data-name="Path 67115"
																						d="M154.093,1.45a3.883,3.883,0,0,0-.7-.7,2.452,2.452,0,0,0-2.337-.459,2.7,2.7,0,0,0-1.584,2.692,1.02,1.02,0,0,0,1.3.974l.02-.006a1.026,1.026,0,0,0,.745-.983c0-.429.135-.7.225-.736a.077.077,0,0,1,.025,0,.674.674,0,0,1,.352.164,1.817,1.817,0,0,1,.323.325,1.018,1.018,0,0,0,1.183.312l.019-.008a1.022,1.022,0,0,0,.429-1.573Z"
																						transform="translate(-149.472 -0.164)"
																						fill="#ffc751" /></g></g> <g
																							data-v-c6d93ce8="" id="Group_22162" data-name="Group 22162"
																							transform="translate(0 1.307)"><g data-v-c6d93ce8="" id="Group_22161"
																								data-name="Group 22161"><path data-v-c6d93ce8=""
																									id="Path_67116"
																									data-name="Path 67116"
																									d="M149.534,30.058a3.88,3.88,0,0,0-.061.68,1.014,1.014,0,0,0,1.288.985l.031-.009a1.026,1.026,0,0,0,.746-.983,1.906,1.906,0,0,1,.023-.285,1.039,1.039,0,0,0-.149-.734l-.011-.018A1.024,1.024,0,0,0,149.534,30.058Z"
																									transform="translate(-149.473 -29.225)"
																									fill="#ffe059" /></g></g></g> <g
																										data-v-c6d93ce8="" id="Group_22166" data-name="Group 22166"
																										transform="translate(0 4.602)"><g data-v-c6d93ce8="" id="Group_22165"
																											data-name="Group 22165"
																											transform="translate(0.694 1.455)"><path
																	data-v-c6d93ce8="" id="Path_67117" data-name="Path 67117"
																	d="M49.635,152.266H30.8a.684.684,0,0,1-.684-.684v-16.16H50.319v16.16A.684.684,0,0,1,49.635,152.266Z"
																	transform="translate(-30.111 -135.422)" fill="#12adb5" /> <g data-v-c6d93ce8=""
																		id="Group_22164"
																		data-name="Group 22164"><path
																		data-v-c6d93ce8="" id="Path_67118" data-name="Path 67118"
																		d="M30.111,135.422H50.319v3.941H30.111Z" transform="translate(-30.111 -135.422)"
																		fill="#0d8ba0" /> <path data-v-c6d93ce8="" id="Path_67119" data-name="Path 67119"
																			d="M50.318,135.42v16.16a.684.684,0,0,1-.684.684H30.794a.684.684,0,0,1-.684-.684V148.6A11.917,11.917,0,0,0,48.182,135.42Z"
																			transform="translate(-30.11 -135.42)"
																			fill="#0d8ba0" /></g></g> <path data-v-c6d93ce8=""
																				id="Path_67120"
																				data-name="Path 67120"
																				d="M35.5,107.127H15.27a.684.684,0,0,1-.684-.684v-2.864a.684.684,0,0,1,.684-.684H35.5a.684.684,0,0,1,.684.684v2.864A.684.684,0,0,1,35.5,107.127Z"
																				transform="translate(-14.586 -102.894)"
																				fill="#3cd3d3" /> <path
																data-v-c6d93ce8="" id="Path_67121" data-name="Path 67121"
																d="M93.172,102.894H81.665a1.055,1.055,0,1,0,0,2.11H93.172a1.055,1.055,0,1,0,0-2.11Z"
																transform="translate(-77.657 -102.894)" fill="#57eae6" /> <path data-v-c6d93ce8=""
																	id="Path_67122"
																	data-name="Path 67122"
																	d="M116,102.894h-1.478a1.055,1.055,0,1,0,0,2.11H116a1.055,1.055,0,1,0,0-2.11Z"
																	transform="translate(-109.047 -102.894)"
																	fill="#a5f9f3" /> <path
																data-v-c6d93ce8="" id="Path_67123" data-name="Path 67123"
																d="M207.45,135.42h4.343v16.845H207.45Z" transform="translate(-198.823 -133.965)"
																fill="#ffaf40" /> <path data-v-c6d93ce8="" id="Path_67124" data-name="Path 67124"
																	d="M207.454,135.422H211.8v3.941h-4.343Z"
																	transform="translate(-198.827 -133.967)"
																	fill="#ff993a" /> <path data-v-c6d93ce8=""
																		id="Path_67125"
																		data-name="Path 67125"
																		d="M204.816,102.894h4.579v4.233h-4.579Z"
																		transform="translate(-196.307 -102.894)"
																		fill="#ffc751" /></g></g> <g
																			data-v-c6d93ce8="" id="Group_22168" data-name="Group 22168"
																			transform="translate(15.28 10.968)"><path data-v-c6d93ce8="" id="Path_67126"
																				data-name="Path 67126"
																				d="M209.632,259.29v-5.518a1.089,1.089,0,1,0-2.178,0v5.518a1.089,1.089,0,0,0,2.178,0Z"
																				transform="translate(-199.521 -252.348)"
																				fill="#ffc751" /> <path
															data-v-c6d93ce8="" id="Path_67127" data-name="Path 67127"
															d="M209.632,286.377V284.5a1.089,1.089,0,0,0-2.178,0v1.876a1.089,1.089,0,1,0,2.178,0Z"
															transform="translate(-199.521 -281.703)" fill="#ffe059" /> <path data-v-c6d93ce8=""
																id="Path_67128"
																data-name="Path 67128"
																d="M32.288,249.5v-3.218a1.089,1.089,0,0,0-2.178,0V249.5a1.089,1.089,0,1,0,2.178,0Z"
																transform="translate(-30.11 -245.197)"
																fill="#3cd3d3"></path> <path
															data-v-c6d93ce8="" id="Path_67129" data-name="Path 67129"
															d="M32.288,268.683v-.48a1.089,1.089,0,0,0-2.178,0v.48a1.089,1.089,0,1,0,2.178,0Z"
															transform="translate(-30.11 -266.134)" fill="#57eae6" /></g> <path
														data-v-c6d93ce8="" id="Path_67130" data-name="Path 67130"
														d="M204.816,102.894h4.579v2.116h-4.579Z" transform="translate(-181.721 -98.291)"
														fill="#ffe059" /> <path data-v-c6d93ce8="" id="Path_67131" data-name="Path 67131"
															d="M211.793,442.87v3.092H207.45v-1.736A11.826,11.826,0,0,0,211.793,442.87Z"
															transform="translate(-184.237 -423.06)"
															fill="#ff993a" /> <path data-v-c6d93ce8=""
																id="Path_67132"
																data-name="Path 67132"
																d="M323.927,290.88v9.206a.684.684,0,0,1-.684.684H316.53C321.116,299.555,323.275,295.67,323.927,290.88Z"
																transform="translate(-288.438 -277.869)"
																fill="#02718c" /></g> <g
																	data-v-c6d93ce8="" id="Group_22169" data-name="Group 22169"
																	transform="translate(4.539 -74.461)"><g data-v-c6d93ce8="" id="Group_22142"
																		data-name="Group 22142"
																		transform="translate(23.371 400.371)"><g
																			data-v-c6d93ce8="" id="Group_22067" data-name="Group 22067" transform="translate(0 0)"><g
																				data-v-c6d93ce8="" id="Group_4697" data-name="Group 4697" transform="translate(0 0)"><circle
																	data-v-c6d93ce8="" id="Ellipse_2225" data-name="Ellipse 2225" cx="7.5" cy="7.5" r="7.5"
																	transform="translate(0.09 0.09)" fill="#0045ff" /></g></g> <g data-v-c6d93ce8=""
																		id="_ffffffff"
																		data-name="#ffffffff"
																		transform="translate(1.739 1.701)"><path
																data-v-c6d93ce8="" id="Path_67106" data-name="Path 67106"
																d="M72.45,31.2a5.884,5.884,0,0,1,4.449-1.58c.283.052.6-.006.837.192.086.535-.408.945-.867,1.1a7.376,7.376,0,0,0-.841.069,9.791,9.791,0,0,0-3.158.617c-.116.01-.272.051-.349-.063C72.421,31.456,72.467,31.315,72.45,31.2Z"
																transform="translate(-70.569 -29.608)" fill="#fff" /> <path data-v-c6d93ce8=""
																	id="Path_67107"
																	data-name="Path 67107"
																	d="M52.766,40.466c.132,0,.281-.045.4.035a4.8,4.8,0,0,1,.668.455.328.328,0,0,1,.026.393,3.962,3.962,0,0,1-2.026,2.034,4.89,4.89,0,0,1-1.24.334c-.3.021-.6,0-.9.012a7.935,7.935,0,0,0-4.825,2.1.491.491,0,0,1-.465.116c-.16-.114-.075-.34-.1-.506.031-.389-.064-.886.283-1.161a9.246,9.246,0,0,1,4-1.76c.585-.13,1.187-.1,1.776-.178a2.363,2.363,0,0,0,1.677-.707A3.235,3.235,0,0,0,52.766,40.466Z"
																	transform="translate(-44.3 -39.728)"
																	fill="#fff" /> <path
																data-v-c6d93ce8="" id="Path_67108" data-name="Path 67108"
																d="M62.025,68.511c.086,0,.172,0,.259,0a3.486,3.486,0,0,1,.51.813.466.466,0,0,1-.064.521,6.555,6.555,0,0,1-4.853,2.675,5.333,5.333,0,0,0-.883.042,5.252,5.252,0,0,0-3.422,1.634c-.3.3-.506.676-.775,1-.109,0-.219-.006-.327-.009-.231-.367-.569-.759-.449-1.225a6.6,6.6,0,0,1,5.591-2.774,5.291,5.291,0,0,0,3.633-1.676C61.541,69.208,61.753,68.836,62.025,68.511Z"
																transform="translate(-51.48 -65.911)" fill="#fff" /> <path data-v-c6d93ce8=""
																	id="Path_67109"
																	data-name="Path 67109"
																	d="M87.378,114.239c.163-.037.4.033.374.239a4.209,4.209,0,0,1-.09,1.177.788.788,0,0,1-.344.386,9.292,9.292,0,0,1-4.551,1.754c-.425.012-.847.063-1.272.06a2.654,2.654,0,0,0-1.905,1.252c-.118.19-.19.4-.3.594a.538.538,0,0,1-.521-.065,2.07,2.07,0,0,1-.584-.449.9.9,0,0,1,.016-.361,4.011,4.011,0,0,1,1.776-1.881,4.629,4.629,0,0,1,1.144-.4c.327-.045.658-.013.986-.047a5.784,5.784,0,0,1,.672-.059,7.934,7.934,0,0,0,3.779-1.522C86.846,114.713,87.079,114.433,87.378,114.239Z"
																	transform="translate(-75.905 -108.575)"
																	fill="#fff" /> <path
																data-v-c6d93ce8="" id="Path_67110" data-name="Path 67110"
																d="M115.543,178.879a10.423,10.423,0,0,0,3.489-.592,1.788,1.788,0,0,1,.482-.013c.082.153.122.371-.051.48a5.915,5.915,0,0,1-5.035,1.372c-.18-.083-.124-.313-.109-.471A1.345,1.345,0,0,1,115.543,178.879Z"
																transform="translate(-109.622 -168.331)" fill="#fff" /></g></g></g> <g
																	data-v-c6d93ce8="" id="Group_22272" data-name="Group 22272"
																	transform="translate(-1492 -574.636)"><rect data-v-c6d93ce8="" id="Rectangle_1"
																		data-name="Rectangle 1" width="15"
																		height="15" rx="7.5"
																		transform="translate(1520 901.076)"
																		fill="#3b52ff" /> <g data-v-c6d93ce8=""
																			id="Group_23"
																			data-name="Group 23"
																			transform="translate(1523.464 904.522)"><g
																				data-v-c6d93ce8="" id="Screen_Shot_2021-06-17_at_23"
																				data-name="Screen Shot 2021-06-17 at 23" transform="translate(0)"><g data-v-c6d93ce8=""
																					id="_e9374dff"
																					data-name="#e9374dff"><path
																	data-v-c6d93ce8="" id="Path_2" data-name="Path 2"
																	d="M82.547,71.5a.8.8,0,0,1,.884.347q1.653,2.848,3.29,5.7a.783.783,0,0,1-.552,1.151.775.775,0,0,1-.811-.369c-.662-1.13-1.307-2.27-1.971-3.4a.77.77,0,0,0-1.249,0c-.689,1.144-1.33,2.319-2.019,3.463a.789.789,0,0,1-1.411-.378c-.045-.512.4-.9.587-1.34.737-1.278,1.48-2.553,2.208-3.835C81.845,72.4,81.969,71.7,82.547,71.5Z"
																	transform="translate(-78.705 -71.475)" fill="#fff" /> <path data-v-c6d93ce8=""
																		id="Path_3"
																		data-name="Path 3"
																		d="M206.286,275.638a.807.807,0,0,1,.887.413c.172.372.509.7.5,1.13a.806.806,0,0,1-.818.773c-.3,0-.6,0-.9,0a.822.822,0,0,1-.733-.8c.007-.379.291-.672.453-.995A.859.859,0,0,1,206.286,275.638Z"
																		transform="translate(-202.399 -271.051)"
																		fill="#fff" /></g></g></g></g></g></svg>
										</span>
										<span data-v-c6d93ce8="" className="text-center name color-white-50">Token airdrop</span>
									</a>
								</li>

								<li data-v-c6d93ce8="" className="nav-item">
									<a data-v-c6d93ce8=""
										onClick={navigateTo('wallet').bind(this)} href="#"
										className={'nav-link ' + (isCurrentRoute(constants.ROUTE.WALLET) ? 'router-link-exact-active router-link-active' : '')}>
										<span
											data-v-c6d93ce8="" className="icon text-center"><svg
												data-v-c6d93ce8="" xmlns="http://www.w3.org/2000/svg" width="19.815"
												height="16.846" viewBox="0 0 19.815 16.846"
												className="non-active iconWallet"><g data-v-c6d93ce8=""
													id="_000000ff"
													data-name="#000000ff"
													transform="translate(-42.663 -106.619)"><path
														data-v-c6d93ce8="" id="Path_30756" data-name="Path 30756"
														d="M45.072,106.675a3.344,3.344,0,0,1,.674-.054H58.933a5.487,5.487,0,0,1,1.171.061,2.972,2.972,0,0,1,2.374,2.926q0,5.458,0,10.916a2.985,2.985,0,0,1-2.939,2.94H46.391a5.837,5.837,0,0,1-1.532-.1,3,3,0,0,1-2.11-2.16,3.184,3.184,0,0,1-.085-.815q0-5.389,0-10.777a2.946,2.946,0,0,1,.652-1.866,2.985,2.985,0,0,1,1.756-1.066m-.426,2.89q0,5.454,0,10.908a1,1,0,0,0,1.007,1.008H59.49a1,1,0,0,0,1-.96c0-.671.008-1.343,0-2.014-1.494,0-2.988,0-4.482,0a3.467,3.467,0,0,1,.048-6.934H60.5q0-1,0-2.01a.99.99,0,0,0-.957-.961q-6.944,0-13.887,0a1,1,0,0,0-1,.963m9.934,5.193a1.5,1.5,0,0,0,.825,1.627,2.538,2.538,0,0,0,1.159.143c1.311,0,2.622,0,3.933,0,0-.99,0-1.98,0-2.97-1.5,0-2.994,0-4.491,0A1.492,1.492,0,0,0,54.58,114.758Z"
														fill="#fff" /></g></svg> <svg data-v-c6d93ce8=""
															xmlns="http://www.w3.org/2000/svg"
															width="19.815" height="16.846"
															viewBox="0 0 19.815 16.846"
															className="active iconWallet"><g
																data-v-c6d93ce8="" id="_000000ff" data-name="#000000ff"
																transform="translate(-42.663 -106.619)"><path data-v-c6d93ce8=""
																	id="Path_30756"
																	data-name="Path 30756"
																	d="M45.072,106.675a3.344,3.344,0,0,1,.674-.054H58.933a5.487,5.487,0,0,1,1.171.061,2.972,2.972,0,0,1,2.374,2.926q0,5.458,0,10.916a2.985,2.985,0,0,1-2.939,2.94H46.391a5.837,5.837,0,0,1-1.532-.1,3,3,0,0,1-2.11-2.16,3.184,3.184,0,0,1-.085-.815q0-5.389,0-10.777a2.946,2.946,0,0,1,.652-1.866,2.985,2.985,0,0,1,1.756-1.066m-.426,2.89q0,5.454,0,10.908a1,1,0,0,0,1.007,1.008H59.49a1,1,0,0,0,1-.96c0-.671.008-1.343,0-2.014-1.494,0-2.988,0-4.482,0a3.467,3.467,0,0,1,.048-6.934H60.5q0-1,0-2.01a.99.99,0,0,0-.957-.961q-6.944,0-13.887,0a1,1,0,0,0-1,.963m9.934,5.193a1.5,1.5,0,0,0,.825,1.627,2.538,2.538,0,0,0,1.159.143c1.311,0,2.622,0,3.933,0,0-.99,0-1.98,0-2.97-1.5,0-2.994,0-4.491,0A1.492,1.492,0,0,0,54.58,114.758Z"
																	fill="#2076fe" /></g></svg></span>
										<span data-v-c6d93ce8=""
											className="text-center name color-white-50">{t('common.wallet')}</span></a></li>
								<li data-v-c6d93ce8="" className="nav-item"><a data-v-c6d93ce8=""
									href="#"
									onClick={this.onLogout}
									className="nav-link borderTop"><span
										data-v-c6d93ce8=""><svg data-v-c6d93ce8=""
											xmlns="http://www.w3.org/2000/svg"
											width="16" height="15" viewBox="0 0 16 15"
											className="mr-2 iconLabel"><g
												data-v-c6d93ce8="" id="log-out" transform="translate(0 -1)"><path
													data-v-c6d93ce8="" id="Path_29008" data-name="Path 29008"
													d="M6.5,5.5v-3a1,1,0,0,1,1-1h7a1,1,0,0,1,1,1v12a1,1,0,0,1-1,1h-7a1,1,0,0,1-1-1v-3"
													fill="none" stroke="#fff" strokeLinecap="round"
													strokeLinejoin="round" strokeMiterlimit="10"
													strokeWidth="1" /> <line data-v-c6d93ce8="" id="Line_1254"
														data-name="Line 1254" x1="11"
														transform="translate(0.5 8.5)"
														fill="none" stroke="#fff"
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeMiterlimit="10"
														strokeWidth="1" /> <path
													data-v-c6d93ce8="" id="Path_29009" data-name="Path 29009"
													d="M3.5,5.5l-3,3,3,3" fill="none" stroke="#fff"
													strokeLinecap="round" strokeLinejoin="round"
													strokeMiterlimit="10" strokeWidth="1" /></g></svg></span>
									<span data-v-c6d93ce8="" className="color-white-50">{t('common.logout')}</span></a>
								</li>
							</ul>
						</div>
						<div data-v-c6d93ce8="" className="bottomSidebar">
							<ul data-v-c6d93ce8="" className="nav flex-column">

							</ul>
						</div>
					</div>
				</div>
				:
				<div data-v-b0e45e8c="" className="mobile">
					<div data-v-9d166468="" data-v-b0e45e8c="" id="leftSidebar"
						className={'sidebarMore anim mobile ' + (is_show_menu ? 'active' : 'inActive')}>
						<div data-v-9d166468="" className="menuProfile">
							<div data-v-9d166468="" className="bodySidebar">
								<a data-v-211ca04e="" href="#" onClick={this.onCloseMenu} className="swapMenu close-side-bar-mobile">
									<i className='fas fa-times' />
								</a>
								<h3 data-v-9d166468="" className="colorWhite font-18 px-4 pt-3">
									<b data-v-9d166468="">{t('common.earn_money')}</b>
								</h3>
								<ul data-v-9d166468="" className="listMenu mb-3">
									<li data-v-9d166468="" className="swapMenu"><a data-v-9d166468=""
										href="#"
										onClick={this.onNavigate('trading')}
										className={'nav-link ' + (isCurrentRoute(constants.ROUTE.TRADING) ? 'router-link-exact-active router-link-active' : '')}>
										<div data-v-9d166468="" className="leftNav">
											<svg data-v-9d166468="" xmlns="http://www.w3.org/2000/svg"
												width="19.776" height="20.801"
												viewBox="0 0 19.776 20.801">
												<g data-v-9d166468="" id="bracket-arrow"
													transform="translate(0 21.189) rotate(-90)">
													<g data-v-9d166468="" id="Path_30752"
														data-name="Path 30752"
														transform="translate(2.447 0)" fill="none"
														strokeLinecap="round" strokeLinejoin="round">
														<path data-v-9d166468=""
															d="M9.5,13.385l3.226-3.5L9.5,6.391V1.5l7.743,8.388L9.5,18.276Z"
															stroke="none" />
														<path data-v-9d166468=""
															d="M 9.499995231628418 18.2755241394043 L 17.24255561828613 9.887763977050781 L 9.499995231628418 1.500003933906555 L 9.499995231628418 6.390714168548584 L 12.72606468200684 9.887763977050781 L 9.499995231628418 13.38481426239014 L 9.499995231628418 18.2755241394043 M 9.499653816223145 19.77558326721191 C 9.316328048706055 19.77558326721191 9.131294250488281 19.74194717407227 8.953784942626953 19.67254447937012 C 8.378564834594727 19.44763374328613 7.99999475479126 18.89314460754395 7.99999475479126 18.2755241394043 L 7.99999475479126 13.38481426239014 C 7.99999475479126 13.00788402557373 8.141904830932617 12.64477443695068 8.397475242614746 12.3677339553833 L 10.68527698516846 9.887763977050781 L 8.397475242614746 7.407793998718262 C 8.141904830932617 7.130753993988037 7.99999475479126 6.767643928527832 7.99999475479126 6.390714168548584 L 7.99999475479126 1.500003933906555 C 7.99999475479126 0.8823840022087097 8.378564834594727 0.3278939723968506 8.953784942626953 0.1029839739203453 C 9.131258964538574 0.03359496966004372 9.316363334655762 -5.543701263377443e-05 9.499653816223145 -5.543701263377443e-05 C 9.910433769226074 -5.543701263377443e-05 10.31252574920654 0.1687753796577454 10.60219478607178 0.4825839698314667 L 18.34475517272949 8.870344161987305 C 18.87515449523926 9.444933891296387 18.87515449523926 10.33059406280518 18.34475517272949 10.90518379211426 L 10.60219478607178 19.29294395446777 C 10.31255149841309 19.60672569274902 9.910395622253418 19.77558326721191 9.499653816223145 19.77558326721191 Z"
															stroke="none" fill="#fff" />
													</g>
													<g data-v-9d166468="" id="Path_30753"
														data-name="Path 30753"
														transform="translate(1.389 1.685)" fill="none"
														strokeLinecap="round" strokeLinejoin="round">
														<path data-v-9d166468=""
															d="M6.687,14.905.5,8.2,6.687,1.5V5.408L4.109,8.2,6.687,11Z"
															stroke="none" />
														<path data-v-9d166468=""
															d="M 6.686956405639648 14.90506172180176 L 6.686956405639648 10.99697208404541 L 4.10905647277832 8.202531814575195 L 6.686956405639648 5.408092021942139 L 6.686956405639648 1.500001788139343 L 0.4999963045120239 8.202531814575195 L 6.686956405639648 14.90506172180176 M 6.687297821044922 16.40512084960938 C 6.276517391204834 16.40512084960938 5.874425888061523 16.23628997802734 5.584756374359131 15.92248153686523 L -0.6022037267684937 9.219951629638672 C -1.132603645324707 8.64536190032959 -1.132603645324707 7.759701728820801 -0.6022037267684937 7.185111999511719 L 5.584756374359131 0.4825818240642548 C 5.87440013885498 0.1688009202480316 6.276555061340332 -5.759948908234946e-05 6.687297821044922 -5.759948908234946e-05 C 6.870623588562012 -5.759948908234946e-05 7.055656909942627 0.03357908129692078 7.233166217803955 0.1029818132519722 C 7.808386325836182 0.3278918266296387 8.186956405639648 0.882381796836853 8.186956405639648 1.500001788139343 L 8.186956405639648 5.408092021942139 C 8.186956405639648 5.785021781921387 8.045045852661133 6.148131847381592 7.78947639465332 6.425171852111816 L 6.149843215942383 8.202531814575195 L 7.78947639465332 9.979891777038574 C 8.045045852661133 10.25693225860596 8.186956405639648 10.620041847229 8.186956405639648 10.99697208404541 L 8.186956405639648 14.90506172180176 C 8.186956405639648 15.52268218994141 7.808386325836182 16.07717132568359 7.233166217803955 16.30208206176758 C 7.055692195892334 16.3714714050293 6.870587825775146 16.40512084960938 6.687297821044922 16.40512084960938 Z"
															stroke="none" fill="#fff" />
													</g>
												</g>
											</svg>
										</div>
										<div data-v-9d166468="" className="rightNav">
											{t('common.transaction')}
										</div>
									</a></li>
								</ul>
								<h3 data-v-9d166468="" className="colorWhite font-18 px-4">
									<b data-v-9d166468="">{t('common.all_events')}</b>
								</h3>
								<ul data-v-9d166468="" className="listMenu mb-3">
									<li data-v-9d166468="" className="swapMenu">
										<a data-v-9d166468="" onClick={this.onChangeState('is_show_notification')} className={'nav-link ' + (isCurrentRoute(constants.ROUTE.CHALLENGE) ? 'router-link-exact-active router-link-active' : '')}>
											<div data-v-9d166468="" className="leftNav">
												<svg xmlns="http://www.w3.org/2000/svg" width="16.545"
													height="18" viewBox="0 0 16.545 18" className="2"><g
														id="bell" transform="translate(0.5 0.5)"><path
															id="Path_35098" data-name="Path 35098"
															d="M18.393,11.572A3.536,3.536,0,0,1,17.24,8.963V6.712A5.884,5.884,0,0,0,11.472,1,5.913,5.913,0,0,0,5.7,6.767v2.2a3.536,3.536,0,0,1-1.153,2.609A2.488,2.488,0,0,0,3.7,13.247c0,1.51,3.131,2.526,7.772,2.526s7.772-1.016,7.772-2.526A2.547,2.547,0,0,0,18.393,11.572Z"
															transform="translate(-3.7 -1)" fill="none" stroke="#fff"
															strokeWidth="1" /> <path id="Path_35099"
																data-name="Path 35099"
																d="M25.619,56.51A22.364,22.364,0,0,1,23.2,56.4a2.491,2.491,0,0,0,2.419,1.787A2.524,2.524,0,0,0,28.038,56.4,22.364,22.364,0,0,1,25.619,56.51Z"
																transform="translate(-17.847 -41.187)"
																fill="none" stroke="#fff"
																strokeWidth="1" /></g></svg>
											</div>
											<div data-v-9d166468="" className="rightNav">
												{t('common.notification')}
												{un_read_count > 0 && <span className='notification-dropdown-button-number'>{un_read_count}</span>}
											</div>
										</a>
										{is_show_notification && <>
											<div className="mask-close" onClick={this.onChangeState('is_show_notification')} />
											<div className="notification-dropdown-body">
												<div className="notification-dropdown-header">
													<h3 className="notification-dropdown-title">
														{t('common.notification')}
														<span onClick={this.onReadAllNotification} className="notification-dropdown-readall"><span><svg
															xmlns="http://www.w3.org/2000/svg" width="38"
															height="21" viewBox="0 0 38 21"><g id="Group_21335"
																data-name="Group 21335"
																transform="translate(-917 -81)"><rect
																	id="Rectangle_4940" data-name="Rectangle 4940"
																	width="38" height="21" rx="3"
																	transform="translate(917 81)" fill="#878098" /> <g
																		id="check-all" transform="translate(924.785 83.931)"><path
																		id="Path_35112" data-name="Path 35112"
																		d="M4.773,18.9,0,14.123l1.3-1.3,3.91,2.607L14.336,5l1.3.652L6.757,18.727a1.3,1.3,0,0,1-1.985.168Z"
																		transform="translate(0 -5)" fill="#fefefe" /> <path
																		id="Path_35113" data-name="Path 35113"
																		d="M28.91,10.3H25.652a.652.652,0,1,1,0-1.3H28.91a.652.652,0,1,1,0,1.3Z"
																		transform="translate(-8.709 -6.393)"
																		fill="#fefefe" /> <path id="Path_35114"
																			data-name="Path 35114"
																			d="M27.516,17.3H21.652a.652.652,0,0,1,0-1.3h5.865a.652.652,0,1,1,0,1.3Z"
																			transform="translate(-7.316 -8.832)"
																			fill="#fefefe" /> <path
																		id="Path_35115" data-name="Path 35115"
																		d="M25.774,24.3H16.652a.652.652,0,0,1,0-1.3h9.123a.652.652,0,0,1,0,1.3Z"
																		transform="translate(-5.574 -11.271)"
																		fill="#fefefe" /></g></g></svg></span> </span>
													</h3>
													<span className="notification-dropdown-view-all" onClick={this.onViewAllNotification}>{t('common.see_all')}</span>
												</div>
												<div className="notification-dropdown-list">
													<Notification list={notification} />
												</div>
											</div>
										</>}
									</li>
									<li data-v-9d166468="" className="swapMenu new">
										<a data-v-9d166468="" href="#" onClick={this.onMaintain} className={'nav-link position-relative new'}>
											<div data-v-9d166468="" className="leftNav">
												<svg data-v-c6d93ce8="" xmlns="http://www.w3.org/2000/svg" width="21.597" height="28.337" viewBox="0 0 21.597 28.337"><g
													data-v-c6d93ce8="" id="Group_22170" data-name="Group 22170"
													transform="translate(-24.357 -313.104)"><g data-v-c6d93ce8="" id="gift-box"
														transform="translate(9.771 313.104)"><g
															data-v-c6d93ce8="" id="Group_22167" data-name="Group 22167"
															transform="translate(14.586 0)"><g data-v-c6d93ce8="" id="Group_22163"
																data-name="Group 22163"
																transform="translate(6.033 0)"><g data-v-c6d93ce8=""
																	id="Group_22153"
																	data-name="Group 22153"
																	transform="translate(4.243)"><g
																		data-v-c6d93ce8="" id="Group_22152" data-name="Group 22152"><g data-v-c6d93ce8=""
																			id="Group_22151"
																			data-name="Group 22151"><path
																				data-v-c6d93ce8="" id="Path_67112" data-name="Path 67112"
																				d="M246.635,7.043a2.119,2.119,0,0,1-.728-.127,2.452,2.452,0,0,1-1.5-1.854,4.455,4.455,0,0,1,.206-2.459A4.455,4.455,0,0,1,246.041.586a2.452,2.452,0,0,1,2.337-.459c1.416.515,1.982,2.41,1.289,4.313a4.455,4.455,0,0,1-1.423,2.016A2.671,2.671,0,0,1,246.635,7.043Zm1.011-4.977a.674.674,0,0,0-.352.164,2.413,2.413,0,0,0-.735,1.08,2.413,2.413,0,0,0-.131,1.3c.055.245.149.352.186.366s.178-.008.378-.16a2.413,2.413,0,0,0,.735-1.08c.341-.938.091-1.612-.055-1.665a.077.077,0,0,0-.025,0Z"
																				transform="translate(-244.323 0)" fill="#ff993a" /></g></g></g> <g
																					data-v-c6d93ce8="" id="Group_22155" data-name="Group 22155"
																					transform="translate(4.243 1.164)"><g data-v-c6d93ce8="" id="Group_22154"
																						data-name="Group 22154"><path data-v-c6d93ce8=""
																							id="Path_67113"
																							data-name="Path 67113"
																							d="M249.666,29.306a4.455,4.455,0,0,1-1.423,2.016,2.672,2.672,0,0,1-1.609.586,2.119,2.119,0,0,1-.728-.127,2.452,2.452,0,0,1-1.495-1.854,4.084,4.084,0,0,1-.046-1.458,3.549,3.549,0,0,0,1.7.429q.169,0,.334-.016a1.907,1.907,0,0,0,.03.592c.055.245.149.352.186.365s.178-.008.378-.161a2.415,2.415,0,0,0,.734-1.08c.017-.047.032-.092.046-.137a3.57,3.57,0,0,0,1.79-2.432A4,4,0,0,1,249.666,29.306Z"
																							transform="translate(-244.322 -26.03)"
																							fill="#f97c15" /></g></g> <g
																								data-v-c6d93ce8="" id="Group_22158" data-name="Group 22158"
																								transform="translate(0 0.007)"><g data-v-c6d93ce8="" id="Group_22157"
																									data-name="Group 22157"><g data-v-c6d93ce8=""
																										id="Group_22156"
																										data-name="Group 22156"><path
																				data-v-c6d93ce8="" id="Path_67114" data-name="Path 67114"
																				d="M151.182,6.619A4.455,4.455,0,0,1,149.759,4.6c-.693-1.9-.126-3.8,1.289-4.313a2.452,2.452,0,0,1,2.337.459,4.455,4.455,0,0,1,1.423,2.016,4.455,4.455,0,0,1,.206,2.459,2.452,2.452,0,0,1-1.5,1.854,2.119,2.119,0,0,1-.728.127,2.671,2.671,0,0,1-1.609-.586Zm.573-4.388c-.145.053-.4.728-.055,1.665a2.413,2.413,0,0,0,.735,1.08c.2.152.34.174.378.16s.131-.12.186-.366a2.413,2.413,0,0,0-.131-1.3,2.413,2.413,0,0,0-.735-1.08.674.674,0,0,0-.352-.164.078.078,0,0,0-.025,0Z"
																				transform="translate(-149.464 -0.163)" fill="#ffaf40" /></g></g></g> <g
																					data-v-c6d93ce8="" id="Group_22160" data-name="Group 22160"
																					transform="translate(0 0.007)"><g data-v-c6d93ce8="" id="Group_22159"
																						data-name="Group 22159"><path data-v-c6d93ce8=""
																							id="Path_67115"
																							data-name="Path 67115"
																							d="M154.093,1.45a3.883,3.883,0,0,0-.7-.7,2.452,2.452,0,0,0-2.337-.459,2.7,2.7,0,0,0-1.584,2.692,1.02,1.02,0,0,0,1.3.974l.02-.006a1.026,1.026,0,0,0,.745-.983c0-.429.135-.7.225-.736a.077.077,0,0,1,.025,0,.674.674,0,0,1,.352.164,1.817,1.817,0,0,1,.323.325,1.018,1.018,0,0,0,1.183.312l.019-.008a1.022,1.022,0,0,0,.429-1.573Z"
																							transform="translate(-149.472 -0.164)"
																							fill="#ffc751" /></g></g> <g
																								data-v-c6d93ce8="" id="Group_22162" data-name="Group 22162"
																								transform="translate(0 1.307)"><g data-v-c6d93ce8="" id="Group_22161"
																									data-name="Group 22161"><path data-v-c6d93ce8=""
																										id="Path_67116"
																										data-name="Path 67116"
																										d="M149.534,30.058a3.88,3.88,0,0,0-.061.68,1.014,1.014,0,0,0,1.288.985l.031-.009a1.026,1.026,0,0,0,.746-.983,1.906,1.906,0,0,1,.023-.285,1.039,1.039,0,0,0-.149-.734l-.011-.018A1.024,1.024,0,0,0,149.534,30.058Z"
																										transform="translate(-149.473 -29.225)"
																										fill="#ffe059" /></g></g></g> <g
																											data-v-c6d93ce8="" id="Group_22166" data-name="Group 22166"
																											transform="translate(0 4.602)"><g data-v-c6d93ce8="" id="Group_22165"
																												data-name="Group 22165"
																												transform="translate(0.694 1.455)"><path
																		data-v-c6d93ce8="" id="Path_67117" data-name="Path 67117"
																		d="M49.635,152.266H30.8a.684.684,0,0,1-.684-.684v-16.16H50.319v16.16A.684.684,0,0,1,49.635,152.266Z"
																		transform="translate(-30.111 -135.422)" fill="#12adb5" /> <g data-v-c6d93ce8=""
																			id="Group_22164"
																			data-name="Group 22164"><path
																			data-v-c6d93ce8="" id="Path_67118" data-name="Path 67118"
																			d="M30.111,135.422H50.319v3.941H30.111Z" transform="translate(-30.111 -135.422)"
																			fill="#0d8ba0" /> <path data-v-c6d93ce8="" id="Path_67119" data-name="Path 67119"
																				d="M50.318,135.42v16.16a.684.684,0,0,1-.684.684H30.794a.684.684,0,0,1-.684-.684V148.6A11.917,11.917,0,0,0,48.182,135.42Z"
																				transform="translate(-30.11 -135.42)"
																				fill="#0d8ba0" /></g></g> <path data-v-c6d93ce8=""
																					id="Path_67120"
																					data-name="Path 67120"
																					d="M35.5,107.127H15.27a.684.684,0,0,1-.684-.684v-2.864a.684.684,0,0,1,.684-.684H35.5a.684.684,0,0,1,.684.684v2.864A.684.684,0,0,1,35.5,107.127Z"
																					transform="translate(-14.586 -102.894)"
																					fill="#3cd3d3" /> <path
																	data-v-c6d93ce8="" id="Path_67121" data-name="Path 67121"
																	d="M93.172,102.894H81.665a1.055,1.055,0,1,0,0,2.11H93.172a1.055,1.055,0,1,0,0-2.11Z"
																	transform="translate(-77.657 -102.894)" fill="#57eae6" /> <path data-v-c6d93ce8=""
																		id="Path_67122"
																		data-name="Path 67122"
																		d="M116,102.894h-1.478a1.055,1.055,0,1,0,0,2.11H116a1.055,1.055,0,1,0,0-2.11Z"
																		transform="translate(-109.047 -102.894)"
																		fill="#a5f9f3" /> <path
																	data-v-c6d93ce8="" id="Path_67123" data-name="Path 67123"
																	d="M207.45,135.42h4.343v16.845H207.45Z" transform="translate(-198.823 -133.965)"
																	fill="#ffaf40" /> <path data-v-c6d93ce8="" id="Path_67124" data-name="Path 67124"
																		d="M207.454,135.422H211.8v3.941h-4.343Z"
																		transform="translate(-198.827 -133.967)"
																		fill="#ff993a" /> <path data-v-c6d93ce8=""
																			id="Path_67125"
																			data-name="Path 67125"
																			d="M204.816,102.894h4.579v4.233h-4.579Z"
																			transform="translate(-196.307 -102.894)"
																			fill="#ffc751" /></g></g> <g
																				data-v-c6d93ce8="" id="Group_22168" data-name="Group 22168"
																				transform="translate(15.28 10.968)"><path data-v-c6d93ce8="" id="Path_67126"
																					data-name="Path 67126"
																					d="M209.632,259.29v-5.518a1.089,1.089,0,1,0-2.178,0v5.518a1.089,1.089,0,0,0,2.178,0Z"
																					transform="translate(-199.521 -252.348)"
																					fill="#ffc751" /> <path
																data-v-c6d93ce8="" id="Path_67127" data-name="Path 67127"
																d="M209.632,286.377V284.5a1.089,1.089,0,0,0-2.178,0v1.876a1.089,1.089,0,1,0,2.178,0Z"
																transform="translate(-199.521 -281.703)" fill="#ffe059" /> <path data-v-c6d93ce8=""
																	id="Path_67128"
																	data-name="Path 67128"
																	d="M32.288,249.5v-3.218a1.089,1.089,0,0,0-2.178,0V249.5a1.089,1.089,0,1,0,2.178,0Z"
																	transform="translate(-30.11 -245.197)"
																	fill="#3cd3d3"></path> <path
																data-v-c6d93ce8="" id="Path_67129" data-name="Path 67129"
																d="M32.288,268.683v-.48a1.089,1.089,0,0,0-2.178,0v.48a1.089,1.089,0,1,0,2.178,0Z"
																transform="translate(-30.11 -266.134)" fill="#57eae6" /></g> <path
															data-v-c6d93ce8="" id="Path_67130" data-name="Path 67130"
															d="M204.816,102.894h4.579v2.116h-4.579Z" transform="translate(-181.721 -98.291)"
															fill="#ffe059" /> <path data-v-c6d93ce8="" id="Path_67131" data-name="Path 67131"
																d="M211.793,442.87v3.092H207.45v-1.736A11.826,11.826,0,0,0,211.793,442.87Z"
																transform="translate(-184.237 -423.06)"
																fill="#ff993a" /> <path data-v-c6d93ce8=""
																	id="Path_67132"
																	data-name="Path 67132"
																	d="M323.927,290.88v9.206a.684.684,0,0,1-.684.684H316.53C321.116,299.555,323.275,295.67,323.927,290.88Z"
																	transform="translate(-288.438 -277.869)"
																	fill="#02718c" /></g> <g
																		data-v-c6d93ce8="" id="Group_22169" data-name="Group 22169"
																		transform="translate(4.539 -74.461)"><g data-v-c6d93ce8="" id="Group_22142"
																			data-name="Group 22142"
																			transform="translate(23.371 400.371)"><g
																				data-v-c6d93ce8="" id="Group_22067" data-name="Group 22067" transform="translate(0 0)"><g
																					data-v-c6d93ce8="" id="Group_4697" data-name="Group 4697" transform="translate(0 0)"><circle
																		data-v-c6d93ce8="" id="Ellipse_2225" data-name="Ellipse 2225" cx="7.5" cy="7.5" r="7.5"
																		transform="translate(0.09 0.09)" fill="#0045ff" /></g></g> <g data-v-c6d93ce8=""
																			id="_ffffffff"
																			data-name="#ffffffff"
																			transform="translate(1.739 1.701)"><path
																	data-v-c6d93ce8="" id="Path_67106" data-name="Path 67106"
																	d="M72.45,31.2a5.884,5.884,0,0,1,4.449-1.58c.283.052.6-.006.837.192.086.535-.408.945-.867,1.1a7.376,7.376,0,0,0-.841.069,9.791,9.791,0,0,0-3.158.617c-.116.01-.272.051-.349-.063C72.421,31.456,72.467,31.315,72.45,31.2Z"
																	transform="translate(-70.569 -29.608)" fill="#fff" /> <path data-v-c6d93ce8=""
																		id="Path_67107"
																		data-name="Path 67107"
																		d="M52.766,40.466c.132,0,.281-.045.4.035a4.8,4.8,0,0,1,.668.455.328.328,0,0,1,.026.393,3.962,3.962,0,0,1-2.026,2.034,4.89,4.89,0,0,1-1.24.334c-.3.021-.6,0-.9.012a7.935,7.935,0,0,0-4.825,2.1.491.491,0,0,1-.465.116c-.16-.114-.075-.34-.1-.506.031-.389-.064-.886.283-1.161a9.246,9.246,0,0,1,4-1.76c.585-.13,1.187-.1,1.776-.178a2.363,2.363,0,0,0,1.677-.707A3.235,3.235,0,0,0,52.766,40.466Z"
																		transform="translate(-44.3 -39.728)"
																		fill="#fff" /> <path
																	data-v-c6d93ce8="" id="Path_67108" data-name="Path 67108"
																	d="M62.025,68.511c.086,0,.172,0,.259,0a3.486,3.486,0,0,1,.51.813.466.466,0,0,1-.064.521,6.555,6.555,0,0,1-4.853,2.675,5.333,5.333,0,0,0-.883.042,5.252,5.252,0,0,0-3.422,1.634c-.3.3-.506.676-.775,1-.109,0-.219-.006-.327-.009-.231-.367-.569-.759-.449-1.225a6.6,6.6,0,0,1,5.591-2.774,5.291,5.291,0,0,0,3.633-1.676C61.541,69.208,61.753,68.836,62.025,68.511Z"
																	transform="translate(-51.48 -65.911)" fill="#fff" /> <path data-v-c6d93ce8=""
																		id="Path_67109"
																		data-name="Path 67109"
																		d="M87.378,114.239c.163-.037.4.033.374.239a4.209,4.209,0,0,1-.09,1.177.788.788,0,0,1-.344.386,9.292,9.292,0,0,1-4.551,1.754c-.425.012-.847.063-1.272.06a2.654,2.654,0,0,0-1.905,1.252c-.118.19-.19.4-.3.594a.538.538,0,0,1-.521-.065,2.07,2.07,0,0,1-.584-.449.9.9,0,0,1,.016-.361,4.011,4.011,0,0,1,1.776-1.881,4.629,4.629,0,0,1,1.144-.4c.327-.045.658-.013.986-.047a5.784,5.784,0,0,1,.672-.059,7.934,7.934,0,0,0,3.779-1.522C86.846,114.713,87.079,114.433,87.378,114.239Z"
																		transform="translate(-75.905 -108.575)"
																		fill="#fff" /> <path
																	data-v-c6d93ce8="" id="Path_67110" data-name="Path 67110"
																	d="M115.543,178.879a10.423,10.423,0,0,0,3.489-.592,1.788,1.788,0,0,1,.482-.013c.082.153.122.371-.051.48a5.915,5.915,0,0,1-5.035,1.372c-.18-.083-.124-.313-.109-.471A1.345,1.345,0,0,1,115.543,178.879Z"
																	transform="translate(-109.622 -168.331)" fill="#fff" /></g></g></g> <g
																		data-v-c6d93ce8="" id="Group_22272" data-name="Group 22272"
																		transform="translate(-1492 -574.636)"><rect data-v-c6d93ce8="" id="Rectangle_1"
																			data-name="Rectangle 1" width="15"
																			height="15" rx="7.5"
																			transform="translate(1520 901.076)"
																			fill="#3b52ff" /> <g data-v-c6d93ce8=""
																				id="Group_23"
																				data-name="Group 23"
																				transform="translate(1523.464 904.522)"><g
																					data-v-c6d93ce8="" id="Screen_Shot_2021-06-17_at_23"
																					data-name="Screen Shot 2021-06-17 at 23" transform="translate(0)"><g data-v-c6d93ce8=""
																						id="_e9374dff"
																						data-name="#e9374dff"><path
																		data-v-c6d93ce8="" id="Path_2" data-name="Path 2"
																		d="M82.547,71.5a.8.8,0,0,1,.884.347q1.653,2.848,3.29,5.7a.783.783,0,0,1-.552,1.151.775.775,0,0,1-.811-.369c-.662-1.13-1.307-2.27-1.971-3.4a.77.77,0,0,0-1.249,0c-.689,1.144-1.33,2.319-2.019,3.463a.789.789,0,0,1-1.411-.378c-.045-.512.4-.9.587-1.34.737-1.278,1.48-2.553,2.208-3.835C81.845,72.4,81.969,71.7,82.547,71.5Z"
																		transform="translate(-78.705 -71.475)" fill="#fff" /> <path data-v-c6d93ce8=""
																			id="Path_3"
																			data-name="Path 3"
																			d="M206.286,275.638a.807.807,0,0,1,.887.413c.172.372.509.7.5,1.13a.806.806,0,0,1-.818.773c-.3,0-.6,0-.9,0a.822.822,0,0,1-.733-.8c.007-.379.291-.672.453-.995A.859.859,0,0,1,206.286,275.638Z"
																			transform="translate(-202.399 -271.051)"
																			fill="#fff" /></g></g></g></g></g></svg>
											</div>
											<div data-v-9d166468="" className="rightNav text-capitalize d-flex">
												Token Airdrop
											</div>
										</a>
									</li>
									<li data-v-9d166468="" className="swapMenu">
										<a data-v-9d166468="" onClick={this.onNavigate('challenge')} className={'nav-link ' + (isCurrentRoute(constants.ROUTE.CHALLENGE) ? 'router-link-exact-active router-link-active' : '')}>
											<div data-v-9d166468="" className="leftNav">
												<svg data-v-211ca04e="" xmlns="http://www.w3.org/2000/svg" width="18.981" height="20.266" viewBox="0 0 18.981 20.266"><g data-v-211ca04e="" id="trophy" transform="translate(-1.5 -0.5)"><path data-v-211ca04e="" id="Path_31718" data-name="Path 31718" d="M5.211,6.78H4.569A2.569,2.569,0,0,1,2,4.211V1H5.211" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" /> <path data-v-211ca04e="" id="Path_31719" data-name="Path 31719" d="M25,6.78h.642a2.569,2.569,0,0,0,2.569-2.569V1H25" transform="translate(-8.23)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" /> <line data-v-211ca04e="" id="Line_1501" data-name="Line 1501" y1="4" transform="translate(11 12.571)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" /> <path data-v-211ca04e="" id="Path_31720" data-name="Path 31720" d="M12.78,13.2h0A5.78,5.78,0,0,1,7,7.422V1H18.559V7.422a5.78,5.78,0,0,1-5.78,5.78Z" transform="translate(-1.789)" fill="none" stroke="#fff" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="1" /> <path data-v-211ca04e="" id="Path_31721" data-name="Path 31721" d="M17.991,29.211H9A3.211,3.211,0,0,1,12.211,26H14.78A3.211,3.211,0,0,1,17.991,29.211Z" transform="translate(-2.505 -8.945)" fill="none" stroke="#fff" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="1" /></g></svg>
											</div>
											<div data-v-9d166468="" className="rightNav">
												{t('common.event')}
											</div>
										</a>
									</li>
								</ul>
								<h3 data-v-9d166468="" className="colorWhite font-18 px-4">
									<b data-v-9d166468="">{t('common.records_management')}</b>
								</h3>
								<ul data-v-9d166468="" className="listMenu mb-3">
									<li data-v-9d166468="" className="swapMenu">
										<div data-v-9d166468="" onClick={this.onNavigate('wallet')} className={'nav-link ' + (isCurrentRoute(constants.ROUTE.WALLET) ? 'router-link-exact-active router-link-active' : '')}>
											<div data-v-9d166468="" className="leftNav">
												<svg data-v-211ca04e="" xmlns="http://www.w3.org/2000/svg"
													width="19.815" height="16.846" viewBox="0 0 19.815 16.846"
													className="iconWallet">
													<g data-v-211ca04e="" id="_000000ff" data-name="#000000ff"
														transform="translate(-42.663 -106.619)">
														<path data-v-211ca04e="" id="Path_30756" data-name="Path 30756"
															d="M45.072,106.675a3.344,3.344,0,0,1,.674-.054H58.933a5.487,5.487,0,0,1,1.171.061,2.972,2.972,0,0,1,2.374,2.926q0,5.458,0,10.916a2.985,2.985,0,0,1-2.939,2.94H46.391a5.837,5.837,0,0,1-1.532-.1,3,3,0,0,1-2.11-2.16,3.184,3.184,0,0,1-.085-.815q0-5.389,0-10.777a2.946,2.946,0,0,1,.652-1.866,2.985,2.985,0,0,1,1.756-1.066m-.426,2.89q0,5.454,0,10.908a1,1,0,0,0,1.007,1.008H59.49a1,1,0,0,0,1-.96c0-.671.008-1.343,0-2.014-1.494,0-2.988,0-4.482,0a3.467,3.467,0,0,1,.048-6.934H60.5q0-1,0-2.01a.99.99,0,0,0-.957-.961q-6.944,0-13.887,0a1,1,0,0,0-1,.963m9.934,5.193a1.5,1.5,0,0,0,.825,1.627,2.538,2.538,0,0,0,1.159.143c1.311,0,2.622,0,3.933,0,0-.99,0-1.98,0-2.97-1.5,0-2.994,0-4.491,0A1.492,1.492,0,0,0,54.58,114.758Z"
															fill="#fff" />
													</g>
												</svg>
											</div>
											<div data-v-9d166468=""
												className="rightNav  position-relative"><span
													data-v-9d166468="">{t('common.deposit_withdrawal')}</span> <span data-v-9d166468=""
														className="totalCount text-uppercase"
														style={{ display: 'none' }}>0</span>
											</div>
										</div>
									</li>
									<li data-v-9d166468="" className="swapMenu"><a data-v-9d166468=""
										href="#"
										onClick={this.onNavigate('profile')}
										className={'nav-link ' + (isCurrentRoute(constants.ROUTE.PROFILE) ? 'router-link-exact-active router-link-active' : '')} >
										<div data-v-9d166468=""
											className="leftNav labelIcon d-flex align-items-center">
											<div data-v-9d166468="" className="avatar user-avatar" style={{ backgroundImage: `url(${avatar || 'images/user.png'})` }}>
												<div data-v-9d166468="" className="overlay" />
											</div>
										</div>
										<span data-v-9d166468=""
											className="rightNav textNickName">{display_name}</span></a>
									</li>
									<li data-v-9d166468="" className="swapMenu">
										<a data-v-9d166468="" href="#" className={'nav-link'}>
											<div data-v-9d166468="" className="leftNav">
												<i class="fa fa-bullhorn" aria-hidden="true"></i>
											</div>
											<div data-v-9d166468="" className="rightNav text-capitalize d-flex">
												{is_verify == 1 ? <span className='colorGreen font-14'><i class="fa fa-check-circle" aria-hidden="true"></i> {t('profile.verified')}</span> : <span className='colorRed font-14'><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {t('profile.not_verified')}</span>}
											</div>
										</a>
									</li>
									<li data-v-9d166468="" className="swapMenu">
										<a data-v-9d166468="" href="#" onClick={preventDefault} className={'nav-link'}>
											<div data-v-9d166468="" className="leftNav">
												<i class="fa fa-address-card" aria-hidden="true"></i>
											</div>
											<div data-v-9d166468="" className="rightNav text-capitalize d-flex">
												{t('common.credit_score')}:  <span className={verified_point < 100 ? 'colorYellow ml-1' : 'colorGreen ml-1'}>{is_verify == 1 ? verified_point : 0}</span>
											</div>
										</a>
									</li>
									{/* <li data-v-9d166468="" className="swapMenu">
										<a data-v-9d166468="" href="#" onClick={preventDefault} className={'nav-link'}>
											<div data-v-9d166468="" className="leftNav">
												<svg data-v-211ca04e="" xmlns="http://www.w3.org/2000/svg" width="22.633" height="21.223" viewBox="0 0 22.633 21.223"><g data-v-211ca04e="" id="transfer" transform="translate(-25.599 -35.35) rotate(-30)"><g data-v-211ca04e="" id="Group_13372" data-name="Group 13372" transform="translate(0 51.198)"><path data-v-211ca04e="" id="Path_30757" data-name="Path 30757" d="M17.79,55.881,13.3,51.386a.642.642,0,0,0-1.1.454v1.926H7.7a.642.642,0,0,0,0,1.284h5.137a.642.642,0,0,0,.642-.642V53.39l2.945,2.945L13.484,59.28V58.261a.642.642,0,0,0-.642-.642H5.778V55.693a.642.642,0,0,0-1.1-.454L.188,59.734a.642.642,0,0,0,0,.908l4.495,4.495a.642.642,0,0,0,.454.188.634.634,0,0,0,.246-.049.642.642,0,0,0,.4-.593V62.756h4.495a.642.642,0,1,0,0-1.284H5.136a.642.642,0,0,0-.642.642v1.018L1.55,60.187l2.945-2.945v1.018a.642.642,0,0,0,.642.642H12.2V60.83a.642.642,0,0,0,1.1.454l4.495-4.495A.642.642,0,0,0,17.79,55.881Z" transform="translate(0 -51.198)" fill="#fff" /></g></g></svg>
											</div>
											<div data-v-9d166468="" className="rightNav text-capitalize d-flex">
												Lệnh
											</div>
										</a>
									</li> */}
									<li data-v-9d166468="" className="swapMenu">
										<a data-v-9d166468="" onClick={this.onNavigate('dashboard')} className={'nav-link ' + (isCurrentRoute(constants.ROUTE.DASHBOARD) ? 'router-link-exact-active router-link-active' : '')}>
											<div data-v-9d166468="" className="leftNav">
												<svg data-v-9d166468="" id="speedometer"
													xmlns="http://www.w3.org/2000/svg" width="17.809"
													height="17.809" viewBox="0 0 17.809 17.809">
													<path data-v-9d166468="" id="Path_30761"
														data-name="Path 30761"
														d="M9.995,9.995a2.226,2.226,0,0,0,0-3.149C9.125,5.978,1,1,1,1S5.978,9.125,6.846,9.995A2.226,2.226,0,0,0,9.995,9.995Z"
														transform="translate(0.484 0.484)"
														fill="#fff" />
													<path data-v-9d166468="" id="Path_30762"
														data-name="Path 30762"
														d="M8.9,0a1.484,1.484,0,1,0,0,2.968A5.936,5.936,0,1,1,2.968,8.9,1.484,1.484,0,1,0,0,8.9,8.9,8.9,0,1,0,8.9,0Z"
														fill="#fff" />
												</svg>
											</div>
											<div data-v-9d166468="" className="rightNav">
												{t('common.transaction_history')}
											</div>
										</a>
									</li>
								</ul>
								<h3 data-v-9d166468="" className="colorWhite font-18 px-4"><b
									data-v-9d166468="">{t('common.settings_help')}</b></h3>
								<ul data-v-9d166468="" className="listMenu mb-3">
									<li data-v-9d166468="" className="swapMenu">
										<a data-v-9d166468="" href="#" onClick={() => window.location.href = cskh_link} className={'nav-link'}>
											<div data-v-9d166468="" className="leftNav">
												<svg data-v-211ca04e="" xmlns="http://www.w3.org/2000/svg" width="18" height="14.858" viewBox="0 0 18 14.858"><g data-v-211ca04e="" id="images" transform="translate(0.001 0)"><g data-v-211ca04e="" id="_000000ff" data-name="#000000ff" transform="translate(-0.001 0)"><path data-v-211ca04e="" id="Path_35553" data-name="Path 35553" d="M7.726,22.6A7.231,7.231,0,0,1,18.213,26.63a2.558,2.558,0,0,1,1.591.909,2.454,2.454,0,0,1,.269,2.634,2.415,2.415,0,0,1-1.988,1.316,7.306,7.306,0,0,1-4.306,4.176,1.118,1.118,0,0,1-1.633.673,1.033,1.033,0,0,1-.121-1.7,1.127,1.127,0,0,1,1.611.26A6.564,6.564,0,0,0,16.852,32.2,6.445,6.445,0,0,0,6.417,24.7a6.4,6.4,0,0,0-1.511,3.753,6.69,6.69,0,0,0,.511,2.99,2.651,2.651,0,0,1-1.63-.137,2.435,2.435,0,0,1,.653-4.638A7.3,7.3,0,0,1,7.726,22.6Z" transform="translate(-2.338 -21.634)" fill="#fff"></path> <path data-v-211ca04e="" id="Path_35554" data-name="Path 35554" d="M50.221,45.859a5.458,5.458,0,0,1,5.952,7.577A5.451,5.451,0,0,1,50,56.546a5.451,5.451,0,0,1,.225-10.686M49.364,53a.441.441,0,0,0-.117.738,2.732,2.732,0,0,0,3.533.307c.221-.19.567-.392.49-.734a.43.43,0,0,0-.746-.186,1.865,1.865,0,0,1-2.353.255C49.915,53.223,49.714,52.861,49.364,53Z" transform="translate(-42.188 -43.8)" fill="#fff" /></g></g></svg>
											</div>
											<div data-v-9d166468="" className="rightNav text-capitalize d-flex">
												{t('common.support_center')}
											</div>
										</a>
									</li>
									<li data-v-9d166468="" className="swapMenu" onClick={this.onLogout}>
										<a data-v-9d166468="" href="#" onClick={preventDefault} className={'nav-link'}>
											<div data-v-9d166468="" className="leftNav">
												<span data-v-211ca04e="" className='icon'>
													<svg data-v-c80df956="" xmlns="http://www.w3.org/2000/svg"
														width="17" height="16" viewBox="0 0 17 16" className="mr-2">
														<g data-v-c80df956="" id="log-out"
															transform="translate(0.5 -0.5)">
															<path data-v-c80df956="" id="Path_29008"
																data-name="Path 29008"
																d="M6.5,5.5v-3a1,1,0,0,1,1-1h7a1,1,0,0,1,1,1v12a1,1,0,0,1-1,1h-7a1,1,0,0,1-1-1v-3"
																fill="none" stroke="#fff" strokeLinecap="round"
																strokeLinejoin="round" strokeMiterlimit="10"
																strokeWidth="2" />
															<line data-v-c80df956="" id="Line_1254"
																data-name="Line 1254" x1="11"
																transform="translate(0.5 8.5)" fill="none"
																stroke="#fff" strokeLinecap="round"
																strokeLinejoin="round" strokeMiterlimit="10"
																strokeWidth="2" />
															<path data-v-c80df956="" id="Path_29009"
																data-name="Path 29009" d="M3.5,5.5l-3,3,3,3"
																fill="none" stroke="#fff" strokeLinecap="round"
																strokeLinejoin="round" strokeMiterlimit="10"
																strokeWidth="2" />
														</g>
													</svg>
												</span>
											</div>
											<div data-v-9d166468="" className="rightNav text-capitalize d-flex">
												{t('common.logout')}
											</div>
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div>
						</div>
					</div>
					{is_show_menu && !is_show_setting && <div className='close-bar' onClick={this.onCloseMenu} />}
				</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		appReducer: state.appReducer,
		memberReducer: state.memberReducer,
		notificationReducer: state.notificationReducer,
	};
}
export default connect(mapStateToProps, {
	logoutUser,
	fetchingUserSuccess,
	updateAppConfigAction,
	fetchingNotification
})(withTranslation()(withRouter(LeftSidebar)));
