import '../../assets/css/main.css';
import { Component } from 'react';
import LeftContent from "./LeftContent";
import Analysis from "./Analysis";
import { connect } from "react-redux";
import { updateAppConfigAction } from "../../redux/actions";
import { getChartHeight } from "../../lib/trade_default_data";
import api from "../../lib/api";
import { isDesktop } from "../../lib/helper";
import { withRouter } from 'react-router-dom';
import io from 'socket.io-client';

class Trading extends Component {
	constructor(props) {
		super(props);
		this.state = {
			trading_data: [],
			psychological_indicators: 0,
			symbol: ''
		}
	}

	fetchCandlestickData = (callback) => {
		io(this.props.appReducer.socket_link).on('chartData', (data) => {
			data = data || [];
			callback(data)
		})
	}

	componentDidMount() {
		if (!this.props.memberReducer?.login_token) {
			return this.props.history.push('/login');
		}
		const searchParams = new URLSearchParams(this.props.location.search);
		const symbol = searchParams.get('symbol');

		if (symbol) {
			this.setState({ symbol: symbol })
			io(this.props.appReducer.socket_link).emit('subscribeToSymbol', symbol);
			this.fetchCandlestickData((data) => {
				data = data || [];
				this.setState({ trading_data: data });
			})
		} else {
			api.getTradingData().then((data) => {
				data = data || [];
				this.setState({ trading_data: data });
			});
		}
		
		getChartHeight();
	}

	onCloseModal = () => {
		this.props.updateAppConfigAction({ win_value: 0 });
	}

	render() {
		if (!this.props.memberReducer?.login_token) {
			return null;
		}
		let { trading_data, symbol } = this.state;

		return (
			<div data-v-b0e45e8c="" id="mainContent">
				<div data-v-b0e45e8c="" className="boxContent">
					<div data-v-5b2de64c="" data-v-b0e45e8c="" id="binaryPage">
						<section data-v-5b2de64c="" className="content position-relative">
							<div data-v-5b2de64c="" className={'h-100 ' + (isDesktop() ? 'd-flex flex-lg-row flex-md-row' : '')}>
								<LeftContent trading_data={trading_data} />
								<Analysis trading_data={trading_data} symbol={symbol} />
							</div>
						</section>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default connect(mapStateToProps, {
	updateAppConfigAction
})(Trading);
