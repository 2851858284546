import { Component } from 'react';
import { connect } from "react-redux";
import {logoutUser, fetchingUser, fetchingUserSuccess, updateAppConfigAction} from "../redux/actions";
import {withRouter} from 'react-router-dom';
import {
    navigateTo
} from '../lib/helper';

import '../assets/css/sticky-footer.css';
import { withTranslation } from 'react-i18next';


class StickyFooter extends Component{
    constructor(props) {
		super(props);
		this.state = {
		};
	}

    render() {
        const { t } = this.props;

        return <div className='sticky-footer'>
            <ul className='menu'>
                <li data-v-6a96ae2b className='item border-right pointer' onClick={navigateTo().bind(this)}>
                    <div><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEKklEQVR4nO2TX0xbdRTHmYlG8WVGfZnGbTWjsKzFQQu0m22ZFMTdDqhru1tqL3+6ksEDGQ9zJgrNIBbiQyUGIRH20DnCTBgxjCwZmhg26Pzz4Pt0iZnGf9HEMXVvH3OgJA5oe1WYxuwkJ7+e7z3nc77N/d28vHvxv48e7tvWgfZEB+NySv2v+Hi6gxJDO6mdx7hmOMaJ9HlV9Ltmolhja2Ebg8Yoi8Y2Yts1HhS9NMr9xjY6C9u4aYySLIjy2CbaYIspQpOple/NrbxvjrBT1N1RnjJHCMsptejyXPqkX+Y21EZZM8WWZq6UtvBlSTOKaBU+HrK0cMrSwi1LCx+mz1Oiy3Ppk/7SFuatGs/8YxPuEA/bNfrtGr/ZwwxKLfq+Jjw2jev2MJ/YmrCKVh7GbNO4bNe4UREmvGLYphGzh/nVHiZpfYlH/5YRRwiPK8QNZ4gPnCqFojkbedLVyHvOED+5GunsWfP1sKUyRNgZ4jtXiOnnghhEldMZ4oKrkW/lue5XV9OI0R3kUrXK19Xq8j/0+XigSqXTrXLTrZJ0qtkvp1Nja7XKYHWQRbdKzJm+5G4VT7XKdbfKXFUQU1YjngCKx88tj58+RSFftIN+XlD8fKEESCn+v/bZSv/SXIBrSoDaJU0hX/hLewLL92/dePEwU14fXfK7LsCOpfowP3p9tK59JfpC5mReOEs8L9tFlz1SZxxUvVw8Us9R1UvnkQZ+URtI+nw8nrcBETzII6qXQbWBRbWBmNpAm+zLOKAd4p1wHb+H67itHWJoI0yss2NI+LJH9mVsjCrkRzxURBQGjiokNsOMcIUve2RfzoGOWuLttZtjRrjC1z1wvIZ4V012M101RI7XEFudoueYSwhft5mTbuIvu7ObecXNiZNVjKxO0bPNCVf4us10HyDeXZn7Nb3mYl/3AXwr2VOJPSe7koTwdZvpcxDvdWQ30+ugu9fJD30OPltJqXudvJpjLiF83WYG9hMf2J/dzMCzXHnDgf/PmtSi52AnhK/bTMJO/E17djMJG6mEjfpVWr3o2eaEK3zdZobLiA+XZzczXE5qqGzZzIgVx9vl1Eoteo65hPB1mxm1EB+zcnvMws+jFqbX6xmzkBpNmxmzEhuzMiK16BmY08ITrvB1m0nuJX6mhNNnLRjG97ItQ08qWbps5t1SgmdKaJda9PX6hSM84Qpft5mJYuITZr46Z2Y2nadX95wrJjVRfOedkVr0Nb1mTq+whCt83WamTMTPm5g5b8InOWnm+TU9e0hN7bnTjNSir+6V+RWWcIWvy8hFIztmipi/UMSnM0X0Z8zdfDNTxOQqfTKtZ5xLc+dlT04zlwoYGffx+Vv9fLRZKfzZAoazGrlcQO3cLhbmmvl4bpiFTUvh72JB9mU0kzLw+lUDs3crZZ+uu3Mv8v5D8Qe6SK3DXSLvJwAAAABJRU5ErkJggg=="/></div>
                    <div>{t('common.home')}</div>
                </li>
                <li data-v-6a96ae2b className='item border-right pointer' onClick={navigateTo('trading').bind(this)}>
                    <div><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEiUlEQVR4nO2WXWxTZRyHC9wgQa5lkphNtrCtZuvHWnrSUQpFutZVVuhWXMM+KKVloG2I4VSNtWji5gctkqzFm04gbA7mRoob8YRsAQnRzAVvIF7oBV6pCWQRE0IMjzkc7JZwKgSoV/slv+Rtz/m/z5PzNm01moUsZCH/Q1aGWFa2j+7n93GgFC3by/6VPegfSaYiwunyMD9URBguRcvDjFSEufVCiDUPlanexfWa3ZiewkMuzghxviZIu+pFUzsrdDtJ63ci6bu5revmu3vrp1h5f5kj8+6/DqjKWHbwgWUHFywdhErYi0IH78s8oQNJ2FFExt5Ozt6O+BRPQo0hypz7a2lD+zwZWwdLXW187WrjjssPJervLj+tMs/VhtjkV2Sa/Egu/zwZbytbvNu46vVS6fVSUYq2bCPY4uPaPZ4PscWnyLRsQ9rqmyfj30rA34I09yBZtN3L934v00/UFnrmMYx+L7/K6+1eRH+LIiNzZX4B3dVMoLN5TqbjVdZ3NXOzq5nWTg++x2x/l4dvfT6WyHt2ezB2NisyXR7ETo8iI3NlfkEm4iIQcSP1eCiLuDgRdjO7x80utQ9fIsHicBOvRdwcDrtI9DRRpXbfHh/LIy6uRNzM7HazN9SEMexWZMIuxLBbkZG5Mr8wGHMSiDmRXt+ENubkz/0vY1ADvOnh2ZiTC1En16JODsecnIxtZjbqpEPt/tArLItupj/qJB9twhjbrMhEnYhRpyIjc2V+YSi+iUDcgRTfhDbu4A9NkYgO+kUHZ/clWPHGZdpiF6l7244gbmT2HQeVajMHHATjDvJvOTDGHYpM3IEoblRk7nPnZA7aCCTtSAkb2qRdXSaRYHFyPX8dtFOXmuREahIOTfL3p+epS9oZSq7nXbW5gzaCSTv5hA1j0q7IJO2I79kVGZkr8wsDH1oJ9DYifWxD22stImNjaV8jfGTjuZFzzIycA7mnJmjttZLus5JSm+trJNjbSL5PwNhnVWR6GxF7rYqMzJX5hYG0QCAtIKUtaNOW4seUEvgxtZbd02fYMH2Gmekxjo0nWJG28FNKoE1t5pBAMCWQPyRgTAmKTNqCmLJw+hMrVWmBqykLWwoDWTOBjAmp34g2Yy4uk21gXdbMbLaB8BGBss8bqM+YGM+YmEpoWKw2c3QtwayZfLYBY8asyBw1I2bNkDFzJ2PmbM7G0sLAgI5ATo90zIg2Z+DugJ5vjptYpbb5gI51AwauDBggZ+BWTs+R4RqWq92b07MrZ+C3nIH8FzqMOb0ikzMgDhiUY3ogX9YRGKxDGvax5PhLrBqqJzdYz0XNf2R4Lc+gYVGx60M61g3Wc/OkDqcse1KHcaieG4N1hOS9B+uVX+0H8pWWwGjt3DfwcA3LR7XcHa3ll7Fafn6cjtZyY6yWz/7dc0yLcVTLbZkzpiU1vlr5P/NAztXQPrGG8/PfG69m40Q1jifpeC0vzmOYJtZwXfOwTFVSPVXJrckqRqYqGS5FJ6uYmarklOZRcmk1usvl7L9cwYFS9FI53dMrWfZIMgtZyEI0T5Z/ANpJnmvB2BOjAAAAAElFTkSuQmCC"/></div>
                    <div>{t('common.transaction')}</div>
                </li>
                <li data-v-6a96ae2b className='item border-right pointer' onClick={navigateTo('dashboard').bind(this)}>
                    <div><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAACXBIWXMAAAsTAAALEwEAmpwYAAAErUlEQVR4nO2WX0yTVxjGP80yTaYoLtkyL6ZQ2kJLaStIEftHQGin0E6hQAH/FSkV45yyOYNshekKy0ADmWH+QTdhE5zCDClSt2UXi1uWiC7qze6W3XkjpmztLjZ/y+eG02lpC7gli0/SvOd7z3me9znnfOc7FYQn+L9DYefpF128IKkjafF2nn1sheRunFI3DSYPT92fl9ahkbo5KHMzKnPzm6yW32W1jMncIKvlV5mb76VumpPcaGfEiNKFRFFDUOliVOnijGgo1UWG0sXXChe3FTV0pdRikztZPMFJ2sGcFCdLlDUUK1ycUtRwS+HCr9iKZlpmtE7e0FYzqNpGvMbJZU0117TVBDROGtKqeCYajcxK4jRODmidjGuqaRU8zJ6SGd0WLi/fTIXY1lcQLz7rNjP0zy2LBhlbSNRt5vpdfh3zYiLrt5Co30BInNm9XAXxhg1cNmzklDAFZDuZr9+A37CRoZhWKKeK13Ir+ez+3GoH0pwq2nKrGJ+KmQlDuZVcz62iJWqSxcG7lnJOmiuRmx00mMu5anYQtJRz1uLA9NB4C3PM5RwRYyTttRUkWhyMF5SijspMkZ2EolJ+sJbys7WMs7Yyyuz28Hst9lnLYLIxD+iXcqCojBHhccBuZ15xSfRmKiuJKy5hzG6f5pF/FEQTjvXRmxHhWEePYx1NwkyjuojnN9mgah3PRcvZaKVkk40rM2qk1kqJy0bIVUxQjDU2iqPhbS0ioaaIkCAwa0aMOK3M315I8OAx6P4CxCg+i/lI3B0W5tSt4U51AYvCDtq2lvhXLeyKxsxuC9p6K8FzIzDxq7cR2lUQ/tjutLFwp5ndd9tmAq+sRhq2wK4CFu3J547HxNwoZhe310yw/zB86YO+92GvmV/q88PfXfX56Pbk86OoL9bZu5b4SUowa18eoX05LIlkpjGPwsY8Am/mE2paQ/CtfIKNBbw8GWdfLvbGPC558khszCMYqYbQvIqrnhzWTzbGY6KwaRUBMXosxL2dg/q9SVbknraJ482r8HpMlDSbGI1oxmug2Wvgo3D9rUZe8hoJeI1YI4o9OIG5XiM3W01ktRjp9RrxRCS1GdG267nVkfn3rT2BQyaWtusJtOkpjMXIXV09e9pW8t0hEwvbVjLWvjLK+6kzC39HFvsf1XdIx9JYjXRkI+nMYqwzG0PnCrwdKxiOmnx0OZquTAJHM0kQpokj6Szo0nGjK5OWI9lIPshk/LCOtJhEujNoPZHO9ePZkT9i4fBhOpLudG6cyOBT0ZTY7k7HG7OQR2B2j5ahHi0jsRo6aWJuzzJe713GWK+WFtFIrxZ/7zLOi7rCVHBGwbx+NUN9aq6dSQu/ZR+riP8kDV2fBnu/mmN9am72q/n2tArDaSWS/jRu9Kk5L+oJ04FHYPagipZBFeODqewfTnr4lA2qaBhQ8dOAiksDKlrPqcj6PJEFA6m8I/LEOOUVeRSGlKh9KYz4UrjlU3BqWEHJBTlLh5P+/LspxvMyEsS8T0GPL4UxXwoXhlNjfFljgV+Bxi+nyS/nij+Z0MVk8Cdz+68Yuihn9GIynhFplN+RmcQ3ChZ9JSFJjP968ScQ/gP8ARyVpIpGpbOoAAAAAElFTkSuQmCC"/></div>
                    <div>{t('common.transaction_history')}</div>
                </li>
                <li data-v-6a96ae2b className='item pointer' onClick={navigateTo('wallet').bind(this)}>
                    <div><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEbklEQVR4nO2Va2xTZRyHqxg1XohL/MAnQN0GbHS3tpO227qONa27dQLtOlvas7Mtox3EQnTt4i6Jbilkk6XTDxQMm0E2AxskDHZkmxlzZmgQTZzghZgY+SAJRgWcSox5TMeQi2wF2n3bL3mS95y85/d/zjlpj0y2kIXEOMtcPLrUTVw0xG/hkahFnvGweZmHv5fXQjQs8/DX8s2URSWTWMO5xE30JLrxRUUNfQmbGI9KZnU15+VVDMurOCCv5oK8ilPT67tgdTWT8mp+mLn2bPjaexqeZOFhpcjrygpOKiv4TClyVSWiVLlYohT5Nb2SVXfblSGQpxD5USPypFKkRFnB1HRnBR8rRHwyGQ/MWaB14dc4Oa1xUpK1kXyNi4taF41aJ99oXey8fb/aSY7WxV6tk93ZAhn/63PSq3VxSutkh8bFt+FOrcA6jZPvNC6EOWX0Dgby7HhvOj6vd/CFzk7V7Xei20h+roPfcu005ToI5Dq4pHuJtJv3WCwsyrWzTe/ga739xmvSO2jQO9gzp4ypHMlo46KxnJ1GG9uNNi4by+meWd+CqZxJk41Pbzp3xmjj5J32Gss5aCznp5l1jclGq8lG15wyZitSiZV3zFaCJVZC84KFUbMVSqwRZKzrkSzrrr1Li4Wksg1YYoXlRTaUlfLcdPd6jljXR5Cxm5EcZgS7mbX2Ui7bzXxoL2U4FjhKGXWYueIsJsVRSr29NIKMWIQkFiNUFtEqFvO2LMYRi+gXi9lSWYS/siiCjLsQyVOA4C4k4C6gI9Yy7gJ6awvxegrwuwsjyHhNSNuMCFuNBLzG2Mt4jfRuNeH1mvB7TRFk6gxIPgOCz0DAZ4hO5hUdS+ryeb/OwIW6fH6Z4WpdPlM+A3/UGfjTZ6B21oJGPVJDHkJTHoEmfXQyjXpGm/Tsa1hLepOe+sY8rjTrSGtey7NhmvS8Gp43a0FLDlKLDqElh0Brzv3LNOt4qlXHP20GHr9+rlXHly05vPDfrGtzZpdp0yK1ZyO0ZRFoz6LDPU6cZ5Qn7kPmofYsptpzSA8fBzNZ3Kbl4o7sG9+v6TnaOWQ61UidzyME1xDoVNPR+hG2ljEmmj9h8b0KvaXm5aCan4Nq3g2qORdUM9W5htB1gmpGg2r6Zy0IZSLtViGEMgmEVHR0jWDrGoa9Q0zsG7x3oV0q1CEVr4Uy2R5ml4qzoUxGdmVyIqTidEhx7R/5julWIHVlIHQrCHQr6Dg+gG3oGIQ5foyJwfsQuqU/g96uDLxdCvzdigg/7f2pSO+lIvSkEtifRsfkIVZNHkaaPMRwmK8O4YpGpieV3p5UvPvT8PekRZDpkyP1yRH6UqjvT2Hk4GqUh+UoYkF/Gqp+OZ/P9Pv7UyLIDCTRdzQZz5FEnh5IZvBoEt/HlGQOHlHw2EASbwwkR/j2jaygZmgFZz5IZKVsnjKykoyhlZw/voLiOTci48GxeN4ci+f3sQSYD04kcGksgfq7tj8gY9H4UuLmg/ANx+ABL2QhsnD+BdJuZY1ziUsyAAAAAElFTkSuQmCC"/></div>
                    <div>{t('common.wallet')}</div>
                </li>
            </ul>
        </div>
    }
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer,
	};
}

export default connect(mapStateToProps, {
	fetchingUser,
	fetchingUserSuccess,
	updateAppConfigAction,
	logoutUser
})(withTranslation()(withRouter(StickyFooter)));