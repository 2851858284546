import _ax from './_ax';
const moment = require('moment');

export function getTradingData(){
	return _ax.get('trading-data');
}

export function getBetResult(bet_id){
	return _ax.get('bet-result?bet_id='+bet_id);
}

export function postBet({bet_condition, bet_value, profit_percent}){
	return _ax.post('bet', { bet_condition, bet_value, profit_percent });
}

export async function getchartData(symbol) {
	const result =  await _ax.get(
        "https://api.binance.com/api/v3/klines",
        {
          params: {
            symbol: "BTCUSDT",
            interval: "1h",
            limit: 100,
          },
        },
      );
	  console.log(result);
}
