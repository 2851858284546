import '../../assets/css/login.css';

import { Component } from 'react';
import api from "../../lib/api";
import {connect} from "react-redux";
import { fetchingUserSuccess } from '../../redux/actions';
import {withRouter} from 'react-router-dom';

import {navigateTo, showNotification} from "../../lib/helper";

class ForgotPassword extends Component{
	constructor(props) {
		super(props);
		this.state = {
			email: this.props.location.state?.email || '',
			is_send_success: false
		};
	}

	onChangeInput = (type) => (e) => {
		this.setState({ [type]: e.target.value });
	}

	onKeyPress = (e) => {
		if(e.code === 'Enter'){
			this.onSignIn(e);
		}
	}

	onSignIn = (e) => {
		let {email} = this.state;
		let isDisableSignButton = !email || email.length === 0;
		if(isDisableSignButton){
			return false;
		}
		api.forgotPassword({ email })
			.then(({ message, success}) => {
				showNotification({message, type: success ? 'success' : 'danger'});
				this.setState({ is_send_success: success });
			});
	}

	render() {
		let {email, is_send_success} = this.state;
		let isDisableSignButton = !email || email.length === 0;
		let { asset_logo, seo_title } = this.props.appReducer;
		return (
			<div data-v-b0e45e8c="" id="mainContent">
				<div data-v-b0e45e8c="" className="boxContent">
					<div data-v-f99ecb42="" data-v-b0e45e8c="" className="boxFix">
						<div data-v-f99ecb42="" className="boxAuthentication show">
							<div data-v-f99ecb42=""
								 className="contentAuthentication d-flex flex-column align-items-center justify-content-between">
								<div data-v-f99ecb42=""
									 className="header d-flex align-items-center justify-content-between w-100">
									<a data-v-f99ecb42="" href="/"
									   style={{ backgroundImage: `url(${asset_logo})` }}
									   className="logoLogin mb-0 router-link-active">BoTrade</a>
									<div data-v-f99ecb42="" onClick={navigateTo('/').bind(this)} className="close-page">
										<span data-v-f99ecb42="" className="pe pe-7s-close" />
									</div>
								</div>
								<div data-v-f99ecb42="" className="formWapper w-100">
									{!is_send_success ? <form data-v-f99ecb42="" className="loginForm colorWhite mt-0"><h2
										data-v-f99ecb42=""
										className="mt5m font-24 color-white font-weight-bold mb-lg-5 mb-3">Quên mật khẩu</h2>
										<div data-v-f99ecb42=""
											 className="md-field md-theme-default md-has-placeholder"><label
											data-v-f99ecb42="" htmlFor="md-input-3037ci4xp">Địa chỉ email *</label>
											<input data-v-f99ecb42="" value={email} onKeyPress={this.onKeyPress} onChange={this.onChangeInput('email')} type="text" aria-autocomplete="false"
												   autoComplete="off" id="md-input-3037ci4xp" placeholder="Điền email" className="md-input" />
												   <small className="md-error" />
										</div>
										<div data-v-f99ecb42="" className="form-group text-center">
											<button data-v-f99ecb42="" type='button' onClick={this.onSignIn} disabled={isDisableSignButton}
													className={'btn-large btn-radius w-100 ' + (isDisableSignButton ? 'btn-secondary btn' : 'siginButton button wbtn')}> Đăng nhập</button>
										</div>
									</form>
										:
										<div data-v-60c89884="">
											<h2 data-v-60c89884="" className="font-weight-bold mb-5 color-white titleHeader">Yêu
											cầu mật khẩu mới thành công!</h2>
											<p data-v-60c89884="" className="color-white">Một liên kết
											xác nhận đã được gửi đến hòm thư điện tử của bạn.<br /> Vui lòng chờ trong ít
												phút để nhận liên kết để đặt lại mật khẩu.</p>
										</div>
									}
								</div>
								<div data-v-f99ecb42="" className="">
									<p data-v-f99ecb42="" className="colorGray bottomText"> Cần có tài khoản {seo_title}?
										<a data-v-f99ecb42="" href="#" onClick={navigateTo('login').bind(this)} className="colorBlue4"> Đăng Nhập</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default connect(mapStateToProps, {
	fetchingUserSuccess
})(withRouter(ForgotPassword));

